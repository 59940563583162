<template>
    <div class="business-balances"> 
        <div 
         v-for="{ currency, amount } in sortedFiatBalances" 
         class="business-balances__balance"
         :key="currency"
        >
            <img class="business-balances__icon" :src="getCurrencyIcon(currency)" />
            <span 
             class="d-flex flex-column align-center" 
             style="width: 100%; font-size: 14px; font-weight: 500; opacity: 0.77; color: black"
            >
                <span>{{ amount }}</span>
                <span>{{ currency }}</span>
            </span>
        </div>

        <div 
         v-for="{ currency, amount } in sortedCryptoBalances" 
         class="business-balances__balance"
         style="gap: 0.5rem; width: 50%;"
         :key="currency"
        >
            <img class="business-balances__icon" :src="getCurrencyIcon(currency)" />
            <span 
             class="d-flex flex-column align-center" 
             style="width: 100%; font-size: 14px; font-weight: 500; opacity: 0.77; color: black"
            >
                <span>{{ amount }}</span>
                <span>{{ currency }}</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BusinessDashboardBalances',
    props: ['fiatBalances', 'cryptoBalances'],
    computed: {
        sortedFiatBalances() {
            return this.fiatBalances.sort((a, b) => a.currency.localeCompare(b.currency))
        },
        sortedCryptoBalances() {
            return this.cryptoBalances.sort((a, b) => a.currency.localeCompare(b.currency))
        },
    },
    methods: {
        getCurrencyIcon(currency) {
            if (currency === 'USDC') {
                return require(`../../assets/images/${currency.toLowerCase()}.png`);
            }

            return require(`../../assets/images/businesses/${currency.toLowerCase()}.png`)
        },
    },
}
</script>

<style lang="scss" scoped>
.business-balances {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  row-gap: 2.4rem;

  &__icon {
    width: 24px !important;
    height: 24px !important;
  }

  &__balance {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>