import axios from "../axios.service";

const DEPOSITS = "sortBy=created&limit=25";
const TRANSFERS = "";
const UNALLOCATED = "limit=25";

class Payment {  
  constructor() {
    this.businessId = null;
  }

  async fetchOrganisationMetadata() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/organisations/metadata`);
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  // TODO: This really needs to be returned from the backend
  async isEuOrganisation() {
    const metadata = await this.fetchOrganisationMetadata();
    return !metadata.enabledCryptoCurrencies.includes('USDT')
  }

  setID(id) {
    this.businessId = id;
    return this;
  }

  async deposits(payload = DEPOSITS, id = this.businessId) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payments?`, {
        params: {
          ...payload,
          businessId: this.businessId,
        },
      });

      return data.payments.map(payment => {
        payment.receivedAmountPerCurrency = payment.deposits
          .filter(deposit => deposit.status === "CONFIRMED")
          .reduce(
            (acc, { depositCurrency, depositAmount }) => {
              if (!depositAmount || parseFloat(depositAmount) === 0) {
                return acc;
              }

              return {
                ...acc,
                [depositCurrency]: (acc[depositCurrency] || 0) + parseFloat(depositAmount),
              };
            },
            {});

        payment.distinctAmounts = Object.keys(payment.receivedAmountPerCurrency ?? {}).length;

        return payment
      }); 
    } catch (error) {
      throw error.response.data.rejection;
    }
  }

  async businessHasDepositsInUSDT() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payments`, {
        params: {
          businessId: this.businessId,
          depositCurrency: 'USDT'
        },
      });
      
      return data.payments.length > 0;
    } catch (error) {
      throw error.response.data.rejection;
    }
  }

  async withdrawals(payload = this.businessId) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}withdrawals/crypto`, {
        params: { ...payload, businessId: this.businessId },
      });
      return data.withdrawals;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async businessHasWithdrawalsInUSDT() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}withdrawals/crypto`, {
        params: { businessId: this.businessId, cryptoCurrency: 'USDT' },
      });
      return data.withdrawals.length > 0;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async settlements(payload = this.businessId) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/withdrawals/fiat`, {
        params: { ...payload, businessId: this.businessId },
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async internalTransfers(payload) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/internal-transfers?`, {
        params: { ...payload, businessId: this.businessId },
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async unassociatedDeposits(payload) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/deposits/unassociated`, {
        params: { ...payload, businessId: this.businessId },
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async manualLedgerMovements(payload) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/manual-movements?`, {
        params: payload,
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async fiatDeposits(payload = this.businessId) {
    try {
      const regularFiatDeposits = await this.fiatTopUps(payload);
      const WLFiatDeposits = await this.fiatTopUpsWL(payload);

      return [...regularFiatDeposits, ...WLFiatDeposits];
    } catch (e) {
      throw e;
    }
  }

  async fiatTopUps(payload = this.businessId) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/deposits/fiat`, {
        params: { ...payload, businessId: this.businessId },
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async fiatTopUpsWL(payload = this.businessId) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/whitelabel-fiat-deposits`, {
        params: { ...payload, businessId: this.businessId },
      });
      return data.map((deposit) => {
        return {
          ...deposit,
          status: "RECEIVED",
          clientLabel: deposit.reference,
          expectedAmount: "-",
          receivedAmount: deposit.amount,
          initiatedBy: "-",
          receivedAt: deposit.createdAt,
        };
      });
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async unallocated(payload = UNALLOCATED) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}deposits/unallocated?${payload}`);
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async getBalanceConversionMetadata(payload) {
    const amount = payload.amount ? `&amount=${payload.amount}` : "";
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_URI}payment/balance/convert/indicative?businessId=${payload.businessId}&amountCurrencyType=${payload.amountCurrencyType}&debitCurrency=${payload.debitCurrency}&creditCurrency=${payload.creditCurrency}${amount}`
      );
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async createBalanceConversion(payload, token) {
    try {
      axios.set2FAHeader(token);
      const { data } = await axios.post(`${process.env.VUE_APP_URI}payment/balance/convert`, payload);
      return data;
    } catch (error) {
      throw error.response.data.message;
    } finally {
      axios.remove2FAHeader();
    }
  }

  async executeBalanceConversion(id) {
    try {
      return await axios.patch(`${process.env.VUE_APP_URI}payment/balance/convert/${id}`);
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async getBalanceConversions(payload) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/balance/convert`, {
        params: { ...payload, businessId: this.businessId },
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
}

export default new Payment();
