import axios from "../axios.service";
import { TokenService } from "@/services/storage.service";
import AppConfig from "../../config/app-config";

function excludeMaldoBusiness(businesses) {
  if (
    TokenService.getConfig("organisation") === AppConfig.customers.MALDOPAY_ORGANISATION_NAME &&
    TokenService.getConfig("role") !== AppConfig.userRoles.ADMIN
  ) {
    return businesses.filter((business) => business.id !== AppConfig.customers.MALDOPAY_SECRET_BUSINESS_ID);
  } else {
    return businesses;
  }
}

class Business {
  async getAll() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_URI}businesses`);
      return excludeMaldoBusiness(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  async create(payload) {
    try {
      return await axios.post(`${process.env.VUE_APP_URI}businesses`, payload);
    } catch (error) {
      throw error.response;
    }
  }

  async update(payload) {
    try {
      const response = await axios.patch(`${process.env.VUE_APP_URI}businesses`, payload);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  async getCurrencies() {
    try {
      return await axios.get(`${process.env.VUE_APP_URI}currencies`);
    } catch (error) {
      throw error.response;
    }
  }

  async getCoinsMetadata() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}withdrawals/metadata`);
      return data;
    } catch (error) {
      throw error.response;
    }
  }
}

export default new Business();
