import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import business from "./modules/business";
import travelRule from "./modules/travelRule";
import * as types from "./types";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isSidebarOpen: true,
  },
  mutations: {
    [types.TOGGLE_SIDEBAR]: (state, payload) => {
      state.isSidebarOpen = payload;
    },
  },
  actions: {
    [types.ACTION_TOGGLE_SIDEBAR]({ commit }, payload) {
      commit(types.TOGGLE_SIDEBAR, payload);
    },
  },
  modules: {
    user,
    business,
    travelRule
  },
});
