import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import router from "./router";
import * as types from "./store/types.js";
import { ACTION_IS_LOGGED_IN } from "./store/constants/user";
import axios from "./services/axios.service";
import AppConfig from "./config/app-config";
import { TokenService } from "./services/storage.service";
import VuePapaParse from "vue-papa-parse";
import vuescroll from "vuescroll/dist/vuescroll-native";
import VueClipboard from "vue-clipboard2";
import VeeValidate, { Validator } from "vee-validate";
import { dictionary } from "./config/vee-validate-dictionary";
import { AnalyticsService } from "@finraxltd/finrax-frontend-common";

Vue.prototype.$AppConfig = AppConfig;
Vue.prototype.$AnalyticsService = new AnalyticsService(process.env.VUE_APP_URI);

//Load domain configuration
AppConfig.setDocumentTitle();
AppConfig.loadFavicon();

//Handle refresh logic
if (TokenService.getConfig("token")) {
  axios.setHeaders();
  store.dispatch(ACTION_IS_LOGGED_IN, true);
  store.dispatch(types.ACTION_STORE_SUPPORTED_CURRENCIES);
}

//Export to CSV
Vue.use(VuePapaParse);

//Moment.js
const moment = require("moment");
Vue.prototype.moment = moment;

//Vue scroll (to be replaced with vuetify)
import("vuescroll/dist/vuescroll.css");
Vue.use(vuescroll);
Vue.prototype.$vuescrollConfig = {
  vuescroll: {
    mode: "native",
    sizeStrategy: "100%",
  },
  bar: {
    showDelay: 300,
    onlyShowBarOnScroll: true,
    keepShow: false,
    background: "#c1c1c1",
    opacity: 0.3,
    hoverStyle: false,
    specifyBorderRadius: false,
    minSize: 0,
    size: "4px",
    disable: false,
  },
  rail: {
    gutterOfSide: "0px",
  },
  scrollPanel: {
    padding: false,
    speed: 1000,
    scrollingX: false,
  },
  scroller: {
    bouncing: false,
  },
};

//VueClipboard
Vue.use(VueClipboard);

//vee-validate dictionary
Validator.localize(dictionary);
Validator.extend("no_special_characters", {
  getMessage: (field) => "Special characters are not allowed",
  validate: (value) => value && !/[<>"';]/.test(value),
});
Validator.extend("minimum_value", {
  getMessage: (field, params, data) => `Minimum amount is ${params.amount} ${params.currency}.`,
  validate: (value, params) => {
    if (params.currency === "-") return true;
    return value >= params.amount;
  },
});

Vue.use(VeeValidate, {
  fieldsBagName: "vvFields",
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
