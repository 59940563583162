<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="d-flex mb-10 align-start">
        <v-icon class="mr-2">$vuetify.icons.businessesDark</v-icon>&nbsp;
        <v-flex>Businesses</v-flex>
        <v-btn
          v-if="hasEditPermissions"
          dark
          :color="$AppConfig.setTheme().positiveButtonAction"
          @click="$router.push(`/create-business`).catch((err) => {})">
          <v-icon :color="$AppConfig.setTheme().buttonTextColor" class="mr-1">mdi-briefcase-plus</v-icon>
          create new business
        </v-btn>
      </v-col>
      <div class="d-flex flex-row flex-wrap justify-center">
        <v-col cols="12" md="12" sm="12" xs="12" lg="12" class="d-flex justify-center" style="max-width: 500px">
          <v-text-field
            :color="$AppConfig.setTheme().primaryColor"
            v-model="searchBusiness"
            style="width: 300px; max-width: 100%"
            label="Search business"
            class="pl-5 pr-5"
            append-icon="mdi-magnify"></v-text-field>
        </v-col>
        <v-col cols="12" md="12" class="d-flex flex-wrap" style="max-width: 1400px" v-if="businesses.length">
          <v-flex
            md6
            sm12
            pa-3
            v-for="business in filter"
            :key="business.businessId"
            :class="filter.length < 3 ? 'lg7' : 'lg4'">
            <v-card class="my-0 pa-2" elevation="3">
              <v-container fill-height fluid style="min-height: 70px" class="d-flex align-start mb-4">
                <v-layout>
                  <v-flex
                    xs12
                    class="d-flex justify-space-between align-center"
                    style="font-size: 18px; min-height: 55px">
                    <span style="max-width: 70%">{{ business.businessName || business.name }}</span>
                    <v-btn
                      v-if="hasEditPermissions"
                      @click="edit(business.businessId || business.id)"
                      depressed
                      text
                      class="ma-0"
                      style="text-transform: capitalize"
                      :style="{ color: $AppConfig.setTheme().primaryColor }">
                      <v-icon class="mr-1" right dark>mdi-pencil</v-icon>
                      Edit
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-card-text class="pt-0 pb-0 mb-4">
                <BusinessDashboardBalances 
                 :fiatBalances="getFiatSettlementBalances(business.businessId)" 
                 :cryptoBalances="getCryptoSettlementBalances(business.businessId)" 
                />                
              </v-card-text>
              <v-card-text></v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  replace
                  dark
                  style="border-radius: 9px; width: 100%"
                  :color="$AppConfig.setTheme().primaryColor"
                  @click="expand(business.businessId || business.id)">
                  <v-icon right dark>mdi-arrow-expand-all</v-icon>
                  Expand business
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-col>
        <v-col cols="12" md="12" v-else>
          <div class="no-payments">
            <img src="../../assets/images/nopayments.png" alt="bitcoin" />
            <div>No businesses created yet</div>
          </div>
        </v-col>
      </div>
    </v-row>
    <v-overlay absolute z-index="222" opacity=".7" :value="load">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <Snackbar :config="snackbarConfig" />
  </div>
</template>
<script>
import Statistics from "../../services/api/statistics";
import { TokenService } from "@/services/storage.service";
import Snackbar from "@/components/Common/Snackbar";
import { mapState } from "vuex";
import Business from "@/services/api/business";
import payment from "../../services/api/payment";
import BusinessDashboardBalances from "./BusinessDashboardBalances.vue";


export default {
  name: "BusinessDashboard",
  components: {
    Snackbar,
    BusinessDashboardBalances,
  },
  data() {
    return {
      load: true,
      businesses: [],
      searchBusiness: "",
      currenciesMetadata: null,
      organisation: null,
      allEnabledCryptoSettlementCurrencies: [],
      allEnabledFiatSettlementCurrencies: [],
      settlementBalances: {},
      snackbarConfig: {
        text: "Failed to load businesses",
        color: "error",
        snackbar: false,
      },
    };
  },
  computed: {
    ...mapState({
      userPermissions: (state) => state.user.permissions,
      supportedCurrencies: (state) => state.business.supportedCurrencies,
    }),
    hasEditPermissions() {
      return this.userPermissions.includes("CREATE_BUSINESS");
    },
    filter() {
      return this.businesses.filter(
        (business) =>
          business?.businessName?.toLowerCase().includes(this.searchBusiness.toLowerCase()) ||
          business?.name?.toLowerCase().includes(this.searchBusiness.toLowerCase())
      );
    },
  },
  methods: {
    getCurrencyIcon(currency) {
            if (currency === 'USDC') {
                return require(`../../assets/images/${currency.toLowerCase()}.png`);
            }

            return require(`../../assets/images/businesses/${currency.toLowerCase()}.png`)
        },
    isEnabledFiatSettlementCurrency(currency) {
      return this.allEnabledFiatSettlementCurrencies.includes(currency);
    },
    isEnabledCryptoSettlementCurrency(currency) {
      return this.allEnabledCryptoSettlementCurrencies.includes(currency);
    },
    roundCurrencyAmount(currency, amount) {
      if (this.isEnabledFiatSettlementCurrency(currency)) {
        return Number(amount).toFixed(2);
      }

      const defaultNetwork = this.supportedCurrencies.find(cur => cur.abbreviation === currency)?.defaultNetwork;
      return Number(amount).toFixed(this.currenciesMetadata?.[currency]?.[defaultNetwork]?.precision ?? 6);
    },
    async fetchOrganisationMetadata() {
      try {
        this.organisation = await payment.fetchOrganisationMetadata();
      } catch (e) {
        this.snackbarConfig = {
          color: "error",
          text: getErrorMessage(e, "Failed to fetch organisation."),
          snackbar: true,
        };
      }
    },
    getFiatSettlementBalances(businessId) {
      if (!businessId || !this.settlementBalances[businessId]) {
        return [];
      }

      return this.settlementBalances[businessId].filter(({ currency }) => this.isEnabledFiatSettlementCurrency(currency));
    },
    getCryptoSettlementBalances(businessId) {
      if (!businessId || !this.settlementBalances[businessId]) {
        return [];
      }

      return this.settlementBalances[businessId].filter(({currency}) => this.isEnabledCryptoSettlementCurrency(currency));
    },
    expand(id) {
      this.$router
        .push({
          name: "business",
          params: { id: id },
        })
        .catch((err) => {});
    },
    edit(id) {
      this.$router
        .push({
          name: "edit-business",
          params: { id: id },
        })
        .catch((err) => {});
    },

    setSettlementBalances(businessesBalances) {
      this.settlementBalances = businessesBalances.reduce((acc, { businessId, balances }) => ({
          ...acc,
          [businessId]: 
            Object.entries(balances)
              .filter(([currency]) => this.isEnabledCryptoSettlementCurrency(currency) || this.isEnabledFiatSettlementCurrency(currency))
              .map(([currency, { amount }]) => ({
                currency,
                amount: this.roundCurrencyAmount(currency, amount),
              }))
              .filter(balances => Boolean(balances))
      }), {});
    }
  },
  async mounted() {    
    try {
      const [currenciesMetadata] = await Promise.all([
        Business.getCoinsMetadata(),
        this.fetchOrganisationMetadata(),
      ]);
      const { enabledCryptoSettlementCurrencies, enabledFiatSettlementCurrencies } = this.organisation;

      this.currenciesMetadata = currenciesMetadata;
      this.allEnabledCryptoSettlementCurrencies = enabledCryptoSettlementCurrencies;
      this.allEnabledFiatSettlementCurrencies = enabledFiatSettlementCurrencies;

      if (TokenService.getConfig("permissions").includes("GET_BALANCES")) {
        const balances = await Statistics.businessBalances()

        if (!balances || !balances.length) return (this.businesses = []);

        const sortedByBalance = balances.sort((a, b) => {
          return (
            parseFloat(b.displayCryptoAmount) +
            parseFloat(b.displayFiatAmount) -
            (parseFloat(a.displayCryptoAmount) + parseFloat(a.displayFiatAmount))
          );
        });
        TokenService.getConfig("role") === this.$AppConfig.userRoles.ADMIN
          ? (this.businesses = sortedByBalance)
          : (this.businesses = sortedByBalance.filter(
              (business) => business.businessId !== this.$AppConfig.customers.MALDOPAY_SECRET_BUSINESS_ID
            ));

        this.setSettlementBalances(this.businesses);
      } else if (TokenService.getConfig("permissions").includes("GET_BUSINESSES")) {
        const allBusinesses = await Business.getAll();
        this.businesses = allBusinesses.filter(
          (business) => business.id !== this.$AppConfig.customers.MALDOPAY_SECRET_BUSINESS_ID
        );
      }
    } catch (error) {
      this.snackbarConfig.text = error.message;
      this.snackbarConfig.snackbar = true;
    } finally {
      this.load = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.no-payments {
  display: flex;
  flex-direction: column;

  align-items: center;

  img {
    width: 100px;
    height: 100px;
    margin-top: 100px;
  }

  div {
    color: #5ab5ce;

    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
  }
}
</style>