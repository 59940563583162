import {
  FETCH_DEPOSITS_WITHOUT_TRAVEL_RULE_DATA,
  SET_DEPOSITS_WITHOUT_TRAVEL_RULE_DATA,
  SET_DEPOSITS_WITHOUT_TRAVEL_RULE_DATA_LOADING,
} from "../constants/travelRule";
import travelRuleService from "../../services/api/travel-rule.service";
import { TokenService } from "../../services/storage.service";

const state = {
  loading: false,
  deposits: [],
};

const mutations = {
  [SET_DEPOSITS_WITHOUT_TRAVEL_RULE_DATA_LOADING]: (state, loading) => {
    state.loading = loading;
  },
  [SET_DEPOSITS_WITHOUT_TRAVEL_RULE_DATA]: (state, deposits) => {
    state.deposits = deposits;
  },
};

const actions = {
  async [FETCH_DEPOSITS_WITHOUT_TRAVEL_RULE_DATA]({ commit }) {
    if (!TokenService.getConfig("permissions").includes("GET_DEPOSITS_WITHOUT_TRAVEL_RULE")) {
      return;
    }

    commit(SET_DEPOSITS_WITHOUT_TRAVEL_RULE_DATA_LOADING, true);
    const deposits = await travelRuleService.fetchDepositsWithoutTravelRuleData();
    commit(SET_DEPOSITS_WITHOUT_TRAVEL_RULE_DATA, deposits);
    commit(SET_DEPOSITS_WITHOUT_TRAVEL_RULE_DATA_LOADING, false);
  },
};

export default {
  state,
  actions,
  mutations,
};
