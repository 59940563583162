import Vue from "vue";
import Router from "vue-router";
import Home from "./components/Login/Home";
import Business from "./components/Business/Business.vue";
import EditBusiness from "./components/Business/EditBusiness";
import BusinessDashboard from "./components/Business/BusinessDashboard.vue";
import { TokenService } from "./services/storage.service";
import store from "./store/store";
import { ACTION_IS_LOGGED_IN } from "./store/constants/user";
import { determineDefaultRoute } from "@/services/permissionHelper";
import AppConfig from "../src/config/app-config";

Vue.use(Router);

const fiatDepositAllowed = AppConfig.loadedDomain === AppConfig.domains.FINRAX_LT || AppConfig.loadedDomain === AppConfig.domains.DIGIBLOX;

function userHasPermission(requiredPermission) {
  const userPermissions = TokenService.getConfig("permissions");

  return userPermissions.includes(requiredPermission);
}

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true,
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("./components/Login/ForgotPassword.vue"),
      meta: {
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true,
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("./components/Login/ResetPassword.vue"),
      meta: {
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true,
      },
    },
    //ORGANISATION STATISTICS
    {
      path: "/statistics",
      name: "statistics",
      component: () => import("./components/Statistics/ReportingOrganisation.vue"),
      beforeEnter: (to, from, next) =>
        userHasPermission("GET_STATISTICS") && userHasPermission("GET_BALANCES") ? next() : next("/"),
    },
    //BUSINESS
    {
      path: "/business/:id",
      name: "business",
      component: Business,
      children: [
        {
          path: "deposits",
          name: `business-${AppConfig.paymentType.DEPOSITS}`,
          component: () => import("./components/Business/Deposits.vue"),
          beforeEnter: (to, from, next) => (userHasPermission("GET_PAYMENT") ? next() : next("/")),
        },
        {
          path: "withdrawals",
          name: `business-${AppConfig.paymentType.WITHDRAWALS}`,
          component: () => import("./components/Business/CryptoWithdrawals.vue"),
          beforeEnter: (to, from, next) => (userHasPermission("GET_WITHDRAWALS") ? next() : next("/")),
        },
        {
          path: "fiat-deposits",
          name: `business-${AppConfig.paymentType.FIAT_TOP_UPS}`,
          component: () => import("./components/Business/FiatDeposits.vue"),
          beforeEnter: (to, from, next) => (userHasPermission("GET_FIAT_DEPOSITS") ? next() : next("/")),
        },
        {
          path: "fiat-withdrawals",
          name: `business-${AppConfig.paymentType.SETTLEMENTS}`,
          component: () => import("./components/Business/FiatWithdrawals.vue"),
          beforeEnter: (to, from, next) => (userHasPermission("GET_FIAT_WITHDRAWALS") ? next() : next("/")),
        },
        {
          path: "internal-transfers",
          name: `business-${AppConfig.paymentType.TRANSFERS}`,
          component: () => import("./components/Business/InternalTransfers.vue"),
          beforeEnter: (to, from, next) => (userHasPermission("GET_INTERNAL_TRANSFERS") ? next() : next("/")),
        },
        {
          path: "unassociated-deposits",
          name: `business-${AppConfig.paymentType.UNASSOCIATED_DEPOSITS}`,
          component: () => import("./components/Business/UnassociatedDeposits.vue"),
          beforeEnter: (to, from, next) => (userHasPermission("GET_INTERNAL_TRANSFERS") ? next() : next("/")),
        },
        {
          path: "manual-operations",
          name: `business-${AppConfig.paymentType.MANUAL_LEDGER_MOVEMENTS}`,
          component: () => import("./components/Business/ManualLedgerMovements.vue"),
          beforeEnter: (to, from, next) => (userHasPermission("FETCH_MANUAL_MOVEMENTS") ? next() : next("/")),
        },
        {
          path: "balance-conversions",
          name: `business-${AppConfig.paymentType.BALANCE_CONVERSIONS}`,
          component: () => import("./components/Business/BalanceConversions.vue"),
          beforeEnter: (to, from, next) => (userHasPermission("GET_BALANCE_CONVERSIONS") ? next() : next("/")),
        },
      ],
    },
    {
      path: "/businesses",
      name: "businesses",
      component: BusinessDashboard,
      beforeEnter: (to, from, next) => (userHasPermission("GET_BUSINESSES") ? next() : next("/")),
    },
    {
      path: "/create-business",
      name: "create-business",
      component: () => import("./components/Business/CreateBusiness.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_BUSINESS") ? next() : next("/")),
    },

    {
      path: "/edit-business/:id",
      name: "edit-business",
      component: EditBusiness,
      beforeEnter: (to, from, next) => (userHasPermission("UPDATE_BUSINESS") ? next() : next("/")),
    },

    //BALANCE CONFIRMATION
    {
      path: "/balance-confirmation",
      name: "balance-confirmation",
      component: () => import("./components/Reporting/BalanceConfirmation/BalanceConfirmation.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("GET_BALANCES") ? next() : next("/")),
    },
    //Transaction Ledger
    {
      path: "/transaction-ledger",
      name: "transaction-ledger",
      component: () => import("./components/Reporting/Transactionledger/TransactionLedger"),
      beforeEnter: (to, from, next) => (userHasPermission("GET_BALANCES") ? next() : next("/")),
    },

    //BILLING
    {
      path: "/crypto-deposit",
      name: "crypto-deposit",
      component: () => import("./components/Billing/RequestPayment/CryptoDeposit.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_PAYMENT") ? next() : next("/")),
    },

    {
      path: "/crypto-withdrawal",
      name: "crypto-withdrawal",
      component: () => import("./components/Billing/CryptoWithdrawal.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_WITHDRAWAL") ? next() : next("/")),
    },
    {
      path: "/internal-transfer",
      name: "internal-transfer",
      component: () => import("./components/Billing/InternalTransfer.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_INTERNAL_TRANSFER") ? next() : next("/")),
    },
    {
      path: "/balance-conversion",
      name: "balance-conversion",
      component: () => import("./components/Billing/ConvertBalances.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_BALANCE_CONVERSION") ? next() : next("/")),
    },
    {
      path: "/fiat-withdrawal",
      name: "fiat-withdrawal",
      component: () => import("./components/Billing/FiatWithdrawal.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_FIAT_WITHDRAWAL") ? next() : next("/")),
    },
    {
      path: "/fiat-deposit",
      name: "fiat-deposit",
      component: () => import("./components/Billing/FiatDeposit.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_FIAT_DEPOSIT") && fiatDepositAllowed ? next() : next("/")),
    },

    //SETTINGS
    {
      path: "/authentication",
      name: "authentication",
      component: () => import("./components/Settings/Authentication.vue"),
    },
    {
      path: "/change-password",
      name: "change-password",
      component: () => import("./components/Settings/UpdatePassword.vue"),
    },
    {
      path: "/api-keys",
      name: "api-keys",
      component: () => import("./components/Settings/APIKeys/ApiKey.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("GET_API_CREDENTIALS") ? next() : next("/")),
    },
    {
      path: "/edit-api-key/:id",
      name: "editapikey",
      component: () => import("./components/Settings/APIKeys/CreateEditApiKey.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("UPDATE_API_CREDENTIALS") ? next() : next("/")),
    },
    {
      path: "/api-keys/delete/:id",
      name: "delete-api-key",
      component: () => import("./components/Common/ResourceDeletion.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("UPDATE_API_CREDENTIALS") ? next() : next("/")),
    },
    {
      path: "/create-api-keys",
      name: "createapikey",
      component: () => import("./components/Settings/APIKeys/CreateEditApiKey.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_API_CREDENTIALS") ? next() : next("/")),
    },
    {
      path: "/whitelisted-addresses",
      name: "whitelisted-addresses",
      component: () => import("./components/Settings/WhitelistedAddresses/WhitelistedAddresses.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("WHITELIST_ADDRESSES") ? next() : next("/")),
    },
    {
      path: "/whitelisted-addresses/edit/:id",
      name: "whitelisted-addresses-edit",
      component: () => import("./components/Settings/WhitelistedAddresses/CreateEditAddress.vue"),
      beforeEnter: (to, from, next) => next("/"), //disabled route
    },
    {
      path: "/whitelisted-addresses/delete/:id",
      name: "delete-whitelisted-address",
      component: () => import("./components/Common/ResourceDeletion.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("WHITELIST_ADDRESSES") ? next() : next("/")),
    },
    {
      path: "/whitelisted-addresses/create",
      name: "whitelisted-addresses-create",
      component: () => import("./components/Settings/WhitelistedAddresses/CreateEditAddress.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("WHITELIST_ADDRESSES") ? next() : next("/")),
    },
    {
      path: "/user-roles",
      name: "user-roles",
      component: () => import("./components/Settings/Roles/RolesList.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("MANAGE_ROLES") ? next() : next("/")),
    },
    {
      path: "/user-roles/edit/:id",
      name: "user-roles-edit",
      component: () => import("./components/Settings/Roles/CreateEditRole.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("MANAGE_ROLES") ? next() : next("/")),
    },
    {
      path: "/user-roles/delete/:id",
      name: "delete-user-role",
      component: () => import("./components/Common/ResourceDeletion.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("MANAGE_ROLES") ? next() : next("/")),
    },
    {
      path: "/user-roles/create",
      name: "user-roles-create",
      component: () => import("./components/Settings/Roles/CreateEditRole.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("MANAGE_ROLES") ? next() : next("/")),
    },
    {
      path: "/users",
      name: "users",
      component: () => import("./components/Settings/Users/AllUsers.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_USER") ? next() : next("/")),
    },
    {
      path: "/edit-user/:id",
      name: "edituser",
      component: () => import("./components/Settings/Users/EditUser.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_USER") ? next() : next("/")),
    },
    {
      path: "/users/delete/:id",
      name: "delete-user",
      component: () => import("./components/Common/ResourceDeletion.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_USER") ? next() : next("/")),
    },
    {
      path: "/create-user",
      name: "create-user",
      component: () => import("./components/Settings/Users/CreateUser.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_USER") ? next() : next("/")),
    },
    {
      path: "/bank-accounts",
      name: "bank-accounts",
      component: () => import("./components/Settings/BankAccounts/BankAccountsList"),
      beforeEnter: (to, from, next) => {
        return userHasPermission("MANAGE_BANK_ACCOUNTS") ||
          (userHasPermission("FETCH_BANK_ACCOUNTS") && userHasPermission("FETCH_BANK_METADATA"))
          ? next()
          : next("/");
      },
    },
    {
      path: "/bank-accounts/:id/edit",
      name: "bank-account-edit",
      component: () => import("./components/Settings/BankAccounts/UpdateBankAccount"),
      beforeEnter: (to, from, next) => (userHasPermission("MANAGE_BANK_ACCOUNTS") ? next() : next("/")),
    },
    {
      path: "/bank-accounts/create",
      name: "bank-account-create",
      component: () => import("./components/Settings/BankAccounts/CreateBankAccount"),
      beforeEnter: (to, from, next) => (userHasPermission("MANAGE_BANK_ACCOUNTS") ? next() : next("/")),
    },
    {
      path: "/withdrawal-approvals",
      name: "withdrawal-approvals",
      component: () => import("./components/Billing/WithdrawalApproval/WithdrawalApprovalHome.vue"),
      beforeEnter: (to, from, next) => (userHasPermission("CREATE_WITHDRAWAL") ? next() : next("/")),
    },
    {
      path: "/travel-rule",
      name: "travel-rule",
      component: () => import('./components/TravelRule/TravelRulePage.vue'),
      beforeEnter: (to, from, next) => (userHasPermission("GET_DEPOSITS_WITHOUT_TRAVEL_RULE") ? next() : next("/")),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);

  const token = TokenService.getConfig("token");

  const pendingOrganisationChoice = !TokenService.getConfig("hasChosenOrganisation");
  const role = !!TokenService.getConfig("role");
  const loggedIn = !!token && role;

  if (!isPublic && !loggedIn) {
    store.dispatch(ACTION_IS_LOGGED_IN, false);
    TokenService.removeConfig();

    return next({
      path: "/",
    });
  }

  if (to.name !== "home") {
    Vue.prototype.$AnalyticsService.capturePageview({
      userId: TokenService.getConfig("id"),
      sessionId: TokenService.getConfig("sessionId"),
      properties: { host: window.location.hostname, path: to.fullPath },
      token: `FRX-WEB ${token}`,
      time: new Date().toISOString(),
    });
  }

  const permissions = TokenService.getConfig("permissions");

  if (loggedIn && pendingOrganisationChoice) {
    if (to.path !== "/") {
      return next("/");
    } else {
      return next();
    }
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next(determineDefaultRoute(permissions));
  }

  if (!to.matched.length) {
    next("/");
  }

  next();
});

export default router;
