<template>
  <v-col cols="12" md="10">
    <v-checkbox
      :color="$AppConfig.setTheme().primaryColor"
      :value="oneToOneRatesEnabled"
      @change="$emit('updateOneToOneRatesEnabled', $event)"
      label="Enable 1:1 stablecoin exchange rates"
      :hint="`Enabling this will lead to crypto deposits and crypto withdrawals using a 1:1 exchange rate for stable currency pairs with USD such as USD/USDC. You will cover the difference between the 1:1 rate and the actual rate. Your
            balance is not going to be updated using the 1:1 rate. ${
              canAdjustFeeDistribution ? 'This feature does not work with processing fee distribution.' : ''
            }`"
      persistent-hint>
    </v-checkbox>
    <v-flex style="width: 100%" class="mt-6 subtitle-2">
      <span :style="{ color: $AppConfig.setTheme().primaryColor }">
        Stablecoin depeg threshold ({{ stablecoinDepegThreshold }}%)
      </span>
    </v-flex>
    <v-slider
      :track-color="'#00c478'"
      style="width: 100%"
      step="0.01"
      :min="oneToOneRatesEnabled ? 1 : 0"
      max="10"
      :disabled="!oneToOneRatesEnabled"
      :value="stablecoinDepegThreshold"
      @input="$emit('updateStablecoinDepegThreshold', $event)" />
    <template v-if="canAdjustFeeDistribution">
      <v-checkbox
        :color="$AppConfig.setTheme().primaryColor"
        :value="feeDistributionEnabled"
        @change="$emit('updateFeeDistributionEnabled', $event)"
        label="Enable processing fee distribution"
        :hint="`Enabling this will pass on part of the processing fee to be paid directly by the end user for crypto deposits.
              ${canAdjustFeeDistribution ? 'This feature does not work with 1:1 stablecoin exchange rates.' : ''}`"
        persistent-hint />
      <v-flex style="width: 100%" class="mt-6 subtitle-2">
        <span :style="{ color: $AppConfig.setTheme().primaryColor }">
          Fee distributed to users ({{ depositFeeDistribution }}%)
        </span>
      </v-flex>
      <v-slider
        :track-color="'#00c478'"
        style="width: 100%"
        step="0.01"
        :max="feeDistributionLimit"
        :disabled="!feeDistributionEnabled"
        :value="depositFeeDistribution"
        @input="$emit('updateDepositFeeDistribution', $event)" />
    </template>
  </v-col>
</template>

<script>
export default {
  name: "OptionalFeaturesConfig",
  props: {
    oneToOneRatesEnabled: {
      type: Boolean,
    },
    stablecoinDepegThreshold: {
      type: Number,
    },
    canAdjustFeeDistribution: {
      type: Boolean,
    },
    feeDistributionEnabled: {
      type: Boolean,
    },
    depositFeeDistribution: {
      type: Number,
    },
    feeDistributionLimit: {
      type: Number,
    },
  },
};
</script>
