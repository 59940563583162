<template>
  <div style="width: 19px">
    <svg v-if="icon === 'stats'" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.48541 16.5645L6.14932 13.4984C6.7989 12.7508 7.89785 12.5912 8.73328 13.1231L10.949 14.534C11.7292 15.0308 12.7482 14.9278 13.4133 14.285L16.3328 11.4628"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M3.48547 8.67969L11.3336 8.67969"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M3.48547 4.66919H16.3328"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'businesses'" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7874 4.01929H3.28435C2.06238 4.01929 1.07178 5.00989 1.07178 6.23186V16.7113C1.07178 17.9333 2.06238 18.9239 3.28435 18.9239H16.7874C18.0094 18.9239 19 17.9333 19 16.7113V6.23186C19 5.00989 18.0094 4.01929 16.7874 4.01929Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M5.25183 4.01932V2.25894C5.25275 1.94553 5.37765 1.64522 5.59927 1.42361C5.82088 1.20199 6.12119 1.07709 6.4346 1.07617H13.6372C13.9506 1.07709 14.2509 1.20199 14.4725 1.42361C14.6942 1.64522 14.8191 1.94553 14.82 2.25894V4.01932"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M18.9538 11.4717H1" :stroke="color" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6.48721 9.67896H6.11603C5.44674 9.67896 4.90417 10.2215 4.90417 10.8908V12.0524C4.90417 12.7217 5.44674 13.2643 6.11603 13.2643H6.48721C7.1565 13.2643 7.69906 12.7217 7.69906 12.0524V10.8908C7.69906 10.2215 7.1565 9.67896 6.48721 9.67896Z"
        :fill="color" />
      <path
        d="M13.9557 9.67896H13.5845C12.9152 9.67896 12.3727 10.2215 12.3727 10.8908V12.0524C12.3727 12.7217 12.9152 13.2643 13.5845 13.2643H13.9557C14.625 13.2643 15.1676 12.7217 15.1676 12.0524V10.8908C15.1676 10.2215 14.625 9.67896 13.9557 9.67896Z"
        :fill="color" />
    </svg>

    <svg v-if="icon === 'bank'" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.26379 17.0469L17.6688 17.0469"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M2.79529 15.0857L16.1377 15.0857"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M4.06201 13.2783L4.06201 6.57661"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M6.79126 13.2783L6.79126 6.57661"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M9.52026 13.2786L9.52026 6.45295"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M12.2491 13.2786L12.2491 6.57686"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M14.9781 13.2786L14.9781 6.57686"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M9.5 0.953125L17.7272 6.35082H1.27276L9.5 0.953125Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'whitelistedAddresses'" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.96155 10.632L8.68248 13.3528L12.6535 8.27881"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M2.86552 4.75885H13.015V3.81141C13.015 2.17032 13.5573 1.50819 14.0878 1.17572C14.6183 0.843239 15.1158 0.82713 15.6101 0.825684H2.86552C2.37119 0.827131 1.89751 1.02414 1.54797 1.37369C1.19842 1.72324 1.0014 2.19691 0.999957 2.69124V4.75885H2.86552Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M16.6049 12.9221H20V15.1574C19.9984 15.6918 19.7854 16.2039 19.4076 16.5818C19.0297 16.9597 18.5176 17.1727 17.9832 17.1742H16.6049"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M4.14881 0.825439H15.5445C15.5554 0.825439 15.5664 0.82544 15.5773 0.825986C15.8369 0.835247 16.0828 0.944685 16.2634 1.13134C16.4441 1.31799 16.5454 1.56735 16.5462 1.8271V16.1727C16.5471 16.438 16.6529 16.6923 16.8406 16.8799C17.0282 17.0676 17.2825 17.1734 17.5479 17.1743H4.14881C3.8834 17.1735 3.62909 17.0677 3.44142 16.88C3.25375 16.6924 3.14796 16.4381 3.14714 16.1727V4.80689"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'cryptoDeposit'" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.29468 6.11011L4.20849 6.11011" :stroke="color" stroke-width="1.3" stroke-linecap="round" />
      <path d="M6.21423 8.17847L6.21423 4.09228" :stroke="color" stroke-width="1.3" stroke-linecap="round" />
      <path
        d="M6.52782 11.0169C3.82189 11.1904 1.48765 9.13744 1.31415 6.43151C1.14065 3.72558 3.19359 1.39133 5.89953 1.21784C7.78535 1.09692 9.49064 2.05739 10.4098 3.56663"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
      <path
        d="M19.9998 12.1091V14.949C19.9998 16.6675 18.7827 18.0607 17.2813 18.0607H13.6185C12.8709 18.0607 12.1938 17.7153 11.7024 17.1564"
        :stroke="color"
        stroke-width="1.3"
        stroke-linejoin="round" />
      <path
        d="M11.9089 12.3444V5.26987C11.9089 4.6305 12.3617 4.2312 12.9203 4.2312C13.4789 4.2312 13.9317 4.6305 13.9317 5.26987V10.0724"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M15.9545 10.0491V9.21475C15.9545 8.65618 15.5017 8.20337 14.9431 8.20337V8.20337C14.3846 8.20337 13.9318 8.65618 13.9318 9.21475V9.93592"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M17.9772 10.0491V9.21475C17.9772 8.65618 17.5244 8.20337 16.9659 8.20337V8.20337C16.4073 8.20337 15.9545 8.65618 15.9545 9.21475V9.93592"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M20.0001 14.1385V9.21475C20.0001 8.65618 19.5472 8.20337 18.9887 8.20337V8.20337C18.4301 8.20337 17.9773 8.65618 17.9773 9.21475V9.93592"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M11.9631 12.4421L10.7175 9.91179C10.0933 8.96878 9.24223 9.18965 8.73836 9.46559C8.23448 9.74153 8.65174 10.3343 8.8928 10.9111L11.5423 16.8822C11.5985 17.0167 11.6744 17.1331 11.7642 17.229"
        :stroke="color"
        stroke-width="1.3"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'cryptoWithdrawal'" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.29468 6.11011L4.20849 6.11011" :stroke="color" stroke-width="1.3" stroke-linecap="round" />
      <path
        d="M6.52782 11.0169C3.82189 11.1904 1.48765 9.13744 1.31415 6.43151C1.14065 3.72558 3.19359 1.39133 5.89953 1.21784C7.78535 1.09692 9.49064 2.05739 10.4098 3.56663"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
      <path
        d="M19.9998 12.1091V14.949C19.9998 16.6675 18.7827 18.0607 17.2813 18.0607H13.6185C12.8709 18.0607 12.1938 17.7153 11.7024 17.1564"
        :stroke="color"
        stroke-width="1.3"
        stroke-linejoin="round" />
      <path
        d="M11.9089 12.3444V5.26987C11.9089 4.6305 12.3617 4.2312 12.9203 4.2312C13.4789 4.2312 13.9317 4.6305 13.9317 5.26987V10.0724"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M15.9545 10.0491V9.21475C15.9545 8.65618 15.5017 8.20337 14.9431 8.20337V8.20337C14.3846 8.20337 13.9318 8.65618 13.9318 9.21475V9.93592"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M17.9772 10.0491V9.21475C17.9772 8.65618 17.5244 8.20337 16.9659 8.20337V8.20337C16.4073 8.20337 15.9545 8.65618 15.9545 9.21475V9.93592"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M20.0001 14.1385V9.21475C20.0001 8.65618 19.5472 8.20337 18.9887 8.20337V8.20337C18.4301 8.20337 17.9773 8.65618 17.9773 9.21475V9.93592"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M11.9631 12.4421L10.7175 9.91179C10.0933 8.96878 9.24223 9.18965 8.73836 9.46559C8.23448 9.74153 8.65174 10.3343 8.8928 10.9111L11.5423 16.8822C11.5985 17.0167 11.6744 17.1331 11.7642 17.229"
        :stroke="color"
        stroke-width="1.3"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'fiatDeposit'" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.1294 12.5515L12.9834 12.5515" :stroke="color" stroke-width="1.3" stroke-linecap="round" />
      <path d="M15.0186 14.6501L15.0186 10.5041" :stroke="color" stroke-width="1.3" stroke-linecap="round" />
      <circle cx="15.0184" cy="12.5772" r="4.33146" :stroke="color" stroke-width="1.3" />
      <circle cx="11.115" cy="5.14909" r="1.7967" :stroke="color" stroke-width="1.3" />
      <path d="M6.8444 9.15007C6.8444 7.79879 5.74898 6.70337 4.39771 6.70337" :stroke="color" stroke-width="1.3" />
      <path d="M6.8444 1.02271C6.8444 2.37398 5.74898 3.4694 4.39771 3.4694" :stroke="color" stroke-width="1.3" />
      <path d="M17.9051 3.4694C16.5538 3.4694 15.4584 2.37398 15.4584 1.02271" :stroke="color" stroke-width="1.3" />
      <path d="M12.0016 3.46867L12.0016 3.46924" :stroke="color" stroke-width="1.3" stroke-miterlimit="10" />
      <path
        d="M9.39366 9.52386H4.95361C4.42108 9.52386 3.98938 9.09216 3.98938 8.55963V1.73864C3.98938 1.20611 4.42108 0.774414 4.95361 0.774414H17.2765C17.809 0.774414 18.2407 1.20611 18.2407 1.73864V6.83902"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
      <path
        d="M8.38016 12.5773H1.96423C1.4317 12.5773 1 12.1456 1 11.6131V4.79211C1 4.25958 1.4317 3.82788 1.96423 3.82788"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
    </svg>

    <svg v-if="icon === 'fiatWithdrawal'" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0001 14.8174V17.3473C19.0001 18.8784 17.9158 20.1195 16.5782 20.1195H13.315C12.649 20.1195 12.0459 19.8118 11.608 19.3139"
        :stroke="color"
        stroke-width="1.3"
        stroke-linejoin="round" />
      <path
        d="M11.7919 15.0272V8.72464C11.7919 8.15504 12.1953 7.79932 12.6929 7.79932C13.1905 7.79932 13.5939 8.15504 13.5939 8.72464V13.0031"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M15.3959 12.9822V12.2389C15.3959 11.7413 14.9925 11.3379 14.4949 11.3379V11.3379C13.9973 11.3379 13.5939 11.7413 13.5939 12.2389V12.8814"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M17.198 12.9822V12.2389C17.198 11.7413 16.7946 11.3379 16.297 11.3379V11.3379C15.7994 11.3379 15.396 11.7413 15.396 12.2389V12.8814"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M18.9999 16.6254V12.2389C18.9999 11.7413 18.5965 11.3379 18.0989 11.3379V11.3379C17.6013 11.3379 17.1979 11.7413 17.1979 12.2389V12.8814"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M11.8401 15.1142L10.7304 12.86C10.1743 12.0199 9.41612 12.2167 8.96723 12.4625C8.51834 12.7083 8.89006 13.2364 9.10482 13.7502L11.4652 19.0698C11.5153 19.1896 11.5829 19.2933 11.6629 19.3788"
        :stroke="color"
        stroke-width="1.3"
        stroke-linejoin="round" />
      <path
        d="M1.10803 16.3831H8.00627"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M2.45093 14.3003L5.93534 14.3003"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M3.56152 12.2869L3.56152 6.08465"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M5.95435 12.2869L5.95435 6.08465"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M8.34705 9.49121L8.34705 5.96987"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M8.32921 0.880127L15.5425 5.87552H1.1159L8.32921 0.880127Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'automaticWithdrawal'" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5345 13.2693C15.7429 13.1556 15.7429 12.8564 15.5346 12.7426L12.54 11.1084C12.3401 10.9993 12.0963 11.144 12.0963 11.3717L12.0963 14.6402C12.0963 14.8679 12.3401 15.0126 12.54 14.9035L15.5345 13.2693Z"
        :fill="color" />
      <circle cx="13.5755" cy="13.006" r="4.77445" :stroke="color" stroke-width="1.3" />
      <path
        d="M0.975342 14.9221H7.04759"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M2.15735 13.0945L5.22454 13.0945"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M3.13513 11.2776L3.13513 5.81802"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M5.24146 11.2776L5.24146 5.81802"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M7.34753 8.81665L7.34753 5.71695"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M7.33188 1.23657L13.6815 5.63382H0.982285L7.33188 1.23657Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'internalTransfer'" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.28324 7.29736L1.28324 2.49355C1.28427 2.13764 1.42481 1.79661 1.67415 1.54495C1.92349 1.29328 2.26137 1.15143 2.61399 1.15039L7.8136 1.15039"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M19.7168 12.7832L19.7168 17.587C19.7157 17.9429 19.5752 18.284 19.3258 18.5356C19.0765 18.7873 18.7386 18.9291 18.386 18.9302H13.1864"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M18.9035 3.27856H12.2113C11.6057 3.27856 11.1147 3.77409 11.1147 4.38536V9.62747C11.1147 10.2387 11.6057 10.7343 12.2113 10.7343H18.9035C19.5091 10.7343 20 10.2387 20 9.62747V4.38536C20 3.77409 19.5091 3.27856 18.9035 3.27856Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M13.1864 2.54231V1.66172C13.1869 1.50495 13.2488 1.35472 13.3586 1.24387C13.4684 1.13301 13.6173 1.07053 13.7726 1.07007H17.3422C17.4976 1.07053 17.6464 1.13301 17.7562 1.24387C17.8661 1.35472 17.928 1.50495 17.9284 1.66172V2.54231"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M19.9516 7.00635H11.1147"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M8.78874 11.4744H2.09656C1.49095 11.4744 1 11.9699 1 12.5812V17.8233C1 18.4345 1.49095 18.9301 2.09656 18.9301H8.78874C9.39435 18.9301 9.8853 18.4345 9.8853 17.8233V12.5812C9.8853 11.9699 9.39435 11.4744 8.78874 11.4744Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M3.07166 10.8045V9.92393C3.07211 9.76715 3.13401 9.61693 3.24385 9.50607C3.35368 9.39522 3.50251 9.33273 3.65784 9.33228H7.22749C7.38282 9.33273 7.53165 9.39522 7.64148 9.50607C7.75131 9.61693 7.81322 9.76715 7.81367 9.92393V10.8045"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M9.83682 15.2021H1" :stroke="color" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'balanceConversion'" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9186 5.96477L12.9186 5.96533" :stroke="color" stroke-width="1.3" stroke-miterlimit="10" />
      <path
        d="M12.9154 6.24459C12.9154 6.19329 12.9166 6.142 12.9188 6.0907C12.9574 5.18749 13.3401 4.33327 13.9885 3.70332C14.6369 3.07336 15.5018 2.71542 16.4058 2.70295C16.4227 2.70239 16.4402 2.70239 16.4576 2.70239C17.0725 2.7024 17.6768 2.86246 18.211 3.16681C18.7453 3.47115 19.1911 3.90932 19.5047 4.4382C19.8182 4.96709 19.9888 5.56849 19.9995 6.18325C20.0102 6.79802 19.8607 7.40499 19.5657 7.94446C19.2707 8.48393 18.8403 8.93734 18.317 9.26008C17.7937 9.58283 17.1953 9.7638 16.5808 9.78521C15.9664 9.80661 15.3569 9.66771 14.8123 9.38216C14.2678 9.09661 13.807 8.67425 13.4752 8.15661C13.1091 7.58613 12.9148 6.92242 12.9154 6.24459Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-miterlimit="10" />
      <path
        d="M16.2863 2.89622C15.9727 2.36733 15.5269 1.92917 14.9926 1.62482C14.4584 1.32047 13.8541 1.16042 13.2392 1.1604C13.2217 1.1604 13.2043 1.1604 13.1874 1.16096C12.2834 1.17344 11.4185 1.53137 10.7701 2.16133C10.1217 2.79129 9.73896 3.6455 9.70041 4.54871C9.69815 4.60001 9.69702 4.65131 9.69702 4.7026C9.69639 5.38043 9.89069 6.04415 10.2568 6.61462C10.5886 7.13226 11.0494 7.55463 11.5939 7.84017C12.1385 8.12572 12.7479 8.26463 13.3624 8.24322"
        :stroke="color"
        stroke-width="1.3"
        stroke-miterlimit="10" />
      <path
        d="M1.00003 7.22778L1.00003 2.37242C1.00107 2.0127 1.14312 1.66801 1.39513 1.41364C1.64715 1.15927 1.98866 1.0159 2.34506 1.01485L7.60046 1.01485"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M19.6312 12.7725L19.6312 17.6278C19.6302 17.9875 19.4881 18.3322 19.2361 18.5866C18.9841 18.841 18.6426 18.9843 18.2862 18.9854H13.0308"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <rect x="1" y="12.7307" width="8.98815" height="6.2545" rx="1" :stroke="color" stroke-width="1.3" />
      <path
        d="M9.90463 16.9557H11.0859C11.607 16.9557 12.0294 16.5618 12.0294 16.076V11.5808C12.0294 11.095 11.607 10.7012 11.0859 10.7012H3.98481C3.4637 10.7012 3.04126 11.095 3.04126 11.5808V13.2841"
        :stroke="color"
        stroke-width="1.3" />
    </svg>

    <svg v-if="icon === 'wallet'" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 4.07202V17.2875C1 18.1933 1.73431 18.9276 2.64013 18.9276H16.9489C17.8547 18.9276 18.589 18.1933 18.589 17.2875V13.8328"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M3.39836 5.86867L3.39836 5.05765C3.39836 4.15183 4.13267 3.41751 5.03849 3.41751L14.6172 3.41751C15.523 3.41751 16.2573 4.15182 16.2573 5.05764L16.2573 6.3071"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M1 3.73617C1 2.2652 2.46862 1.07275 4.28026 1.07275H15.3088C17.1204 1.07275 18.589 2.2652 18.589 3.73617V9.36424"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M1 3.73608C1 5.20705 2.8053 6.3995 5.03226 6.3995H11.8106H15.589C17.2459 6.3995 18.589 7.74264 18.589 9.3995V10.4374"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <rect
        x="14.6637"
        y="10.5488"
        width="5.33633"
        height="3.1855"
        rx="1.59275"
        :stroke="color"
        stroke-width="1.3"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'ledger'" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.16504 17.509H9.7277"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M6.16504 13.5813H12.546"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M6.16504 9.65405H12.546"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M2.86552 6.14125H13.015V5.05582C13.015 3.17572 13.5573 2.41717 14.0878 2.03627C14.6183 1.65537 15.1158 1.63691 15.6101 1.63525H2.86552C2.37118 1.63691 1.89751 1.86262 1.54796 2.26307C1.19842 2.66353 1.0014 3.20619 0.999956 3.77251V6.14125H2.86552Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M16.6049 15.4932H20V18.054C19.9984 18.6662 19.7854 19.2529 19.4076 19.6858C19.0297 20.1187 18.5176 20.3628 17.9832 20.3645H16.6049"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M4.14881 1.63501H15.5445C15.5554 1.63501 15.5664 1.63501 15.5773 1.63564C15.8369 1.64625 16.0828 1.77162 16.2634 1.98546C16.4441 2.1993 16.5454 2.48497 16.5462 2.78256V19.2174C16.5471 19.5214 16.6529 19.8127 16.8406 20.0277C17.0282 20.2427 17.2825 20.3639 17.5479 20.3649H4.14881C3.8834 20.364 3.62909 20.2428 3.44142 20.0278C3.25375 19.8128 3.14795 19.5215 3.14714 19.2174V6.19632"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'lock'" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3569 7.8186H4.64313C2.63108 7.8186 1 9.23193 1 10.9754V19.5573C1 21.3008 2.63108 22.7141 4.64313 22.7141H16.3569C18.3689 22.7141 20 21.3008 20 19.5573V10.9754C20 9.23193 18.3689 7.8186 16.3569 7.8186Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M15.1543 7.81815V5.03292C15.1543 3.96332 14.6639 2.93753 13.7911 2.18121C12.9182 1.42489 11.7344 1 10.5 1C9.2656 1 8.08176 1.42489 7.20891 2.18121C6.33606 2.93753 5.8457 3.96332 5.8457 5.03292V7.81815"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M10.5001 15.7144C11.927 15.7144 13.0838 14.712 13.0838 13.4756C13.0838 12.2391 11.927 11.2368 10.5001 11.2368C9.07315 11.2368 7.91638 12.2391 7.91638 13.4756C7.91638 14.712 9.07315 15.7144 10.5001 15.7144Z"
        :fill="color" />
      <path
        d="M11.9578 18.6683H9.04199L9.85217 15.2009H11.114L11.9578 18.6683Z"
        :fill="color"
        :stroke="color"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'fingerprint'" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.18392 11.7078C1.06333 11.1178 1 10.5068 1 9.88112C1 6.25244 3.12981 3.1212 6.20755 1.66927M10.0747 0.806396C13.0482 0.806396 15.6877 2.23653 17.3428 4.44648"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M8.02039 18.6941C5.0299 17.532 2.92982 15.3944 1.94207 13.2969"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M17.8922 10.113C18.1794 11.178 18.2485 12.2503 18.1268 13.2826"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M9.86047 14.8003C8.87444 14.3061 8.02797 13.6441 7.33833 12.8664"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M17.3654 8.31624C14.2593 2.68653 9.79031 2.02893 6.72071 3.916C3.65111 5.80307 2.576 8.79304 3.71173 11.7135C4.39118 13.4606 7.38596 16.6834 11.552 17.7601"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
      <path
        d="M14.221 14.4834C14.221 14.4834 12.3478 14.2494 10.3818 12.5174C9.08599 11.3759 8.40758 10.2639 8.09562 9.63842C7.93465 9.31565 7.96592 8.94139 8.13415 8.62234V8.62234C8.33366 8.24395 8.70279 7.98445 9.12637 7.92479L9.18678 7.91628C9.62139 7.85507 10.0603 7.99326 10.372 8.30231C10.7977 8.72449 11.4321 9.37538 12.0141 10.0579C12.6958 10.8572 13.4808 11.4195 13.9065 11.6938C14.1095 11.8246 14.3475 11.8846 14.589 11.8846V11.8846C15.1434 11.8846 15.68 11.5577 15.6684 11.0034C15.619 8.64258 12.3058 4.93636 9.04656 5.50656C7.52712 5.77239 6.36971 7.11173 5.9561 8.59776C5.70166 9.5119 5.78727 10.4099 6.40129 11.4058"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
    </svg>

    <svg v-if="icon === 'api'" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.20702 18.2299C6.59326 18.3967 5.87809 18.7623 5.29902 18.8717L4.21026 18.0791C4.13287 17.4907 4.25962 16.6955 4.23027 16.0617L3.34164 14.8422C2.74789 14.6193 1.95134 14.4953 1.41496 14.2391L1 12.9622C1.2842 12.4485 1.8526 11.8814 2.20085 11.3437V9.79862C1.8526 9.26091 1.2842 8.69384 1 8.18015L1.41496 6.90324C1.95134 6.64706 2.7479 6.52298 3.34164 6.30015L4.23027 5.08063C4.25962 4.44685 4.13287 3.65163 4.20892 3.06321L5.29902 2.27066C5.87942 2.38141 6.5946 2.74566 7.20702 2.91244L8.6427 2.44412C9.03364 1.95043 9.3979 1.23126 9.81152 0.800293H11.1885C11.6021 1.23126 11.9664 1.95043 12.3573 2.44412L13.793 2.91244C14.4067 2.74566 15.1219 2.38007 15.701 2.27066L16.7897 3.06321C16.8671 3.65163 16.7404 4.44685 16.7697 5.08063L17.6584 6.30015C18.2521 6.52298 19.0487 6.64706 19.585 6.90324L20 8.18015C19.7158 8.69384 19.1474 9.26091 18.7992 9.79862V11.3437C19.1474 11.8814 19.7158 12.4485 20 12.9622L19.585 14.2391C19.0487 14.4953 18.2521 14.6193 17.6584 14.8422L16.7697 16.0617C16.7404 16.6955 16.8671 17.4907 16.7911 18.0791L15.701 18.8717C15.1206 18.7609 14.4054 18.3967 13.793 18.2299L12.3573 18.6982C11.9664 19.1919 11.6021 19.9111 11.1885 20.342H9.81152C9.3979 19.9111 9.03364 19.1919 8.6427 18.6982L7.20702 18.2299Z"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M7.40866 13.5574C7.40866 13.9164 7.69968 14.2074 8.05866 14.2074C8.41765 14.2074 8.70866 13.9164 8.70866 13.5574H7.40866ZM4.60457 13.5574C4.60457 13.9164 4.89558 14.2074 5.25457 14.2074C5.61355 14.2074 5.90457 13.9164 5.90457 13.5574H4.60457ZM5.9908 9.1876H7.32243V7.8876H5.9908V9.1876ZM7.40866 9.27383V13.5574H8.70866V9.27383H7.40866ZM5.90457 13.5574V9.27383H4.60457V13.5574H5.90457ZM7.32243 9.1876C7.37005 9.1876 7.40866 9.22621 7.40866 9.27383H8.70866C8.70866 8.50824 8.08802 7.8876 7.32243 7.8876V9.1876ZM5.9908 7.8876C5.2252 7.8876 4.60457 8.50824 4.60457 9.27383H5.90457C5.90457 9.22621 5.94318 9.1876 5.9908 9.1876V7.8876ZM5.23901 11.7162H8.03698V10.4162H5.23901V11.7162Z"
        :fill="color" />
      <path
        d="M10.4417 13.5574V9.27383C10.4417 8.86722 10.7713 8.5376 11.1779 8.5376H12.5095C12.9161 8.5376 13.2457 8.86722 13.2457 9.27383V10.2633C13.2457 10.6699 12.9161 10.9995 12.5095 10.9995H10.6145"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M15.7612 13.5574V11.0475V8.5376" :stroke="color" stroke-width="1.3" stroke-linecap="round" />
    </svg>

    <svg
      v-if="icon === 'user'"
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.49998" cy="7.37999" r="5.7876" :stroke="color" stroke-width="1.3" />
      <path
        d="M9.5 13.2192C6.02043 13.2192 3.02918 15.7584 1.71403 17.8104C1.11801 18.7404 1.89543 19.6528 3 19.6528H16C17.1046 19.6528 17.882 18.7404 17.286 17.8104C15.9708 15.7584 12.9796 13.2192 9.5 13.2192Z"
        :stroke="color"
        stroke-width="1.3" />
    </svg>

    <svg
      v-if="icon === 'group'"
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.5193" cy="4.81386" r="3.81825" :stroke="color" stroke-width="1.3" />
      <path
        d="M9.51928 8.63232C7.60974 8.63232 5.94817 10.407 5.09257 11.9992C4.56973 12.9722 5.37321 13.8846 6.47778 13.8846H12.5608C13.6653 13.8846 14.4688 12.9722 13.946 11.9992C13.0904 10.407 11.4288 8.63232 9.51928 8.63232Z"
        :stroke="color"
        stroke-width="1.3" />
      <path
        d="M3.3296 4.15186C2.43418 4.53851 1.8075 5.42948 1.8075 6.46681C1.8075 7.85878 2.93591 8.98719 4.32788 8.98719C4.49523 8.98719 4.65878 8.97088 4.81701 8.93976"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
      <path
        d="M4.32783 8.9873C3.12049 8.9873 2.06332 10.5204 1.48002 11.9535C1.06362 12.9766 1.89543 13.8845 3 13.8845H6.87885"
        :stroke="color"
        stroke-width="1.3" />
      <path
        d="M15.6704 4.15186C16.5658 4.53851 17.1925 5.42948 17.1925 6.46681C17.1925 7.85878 16.0641 8.98719 14.6721 8.98719C14.5048 8.98719 14.3412 8.97088 14.183 8.93976"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
      <path
        d="M14.6722 8.9873C15.8795 8.9873 16.9367 10.5204 17.52 11.9535C17.9364 12.9766 17.1046 13.8845 16 13.8845H12.1212"
        :stroke="color"
        stroke-width="1.3" />
    </svg>

    <svg v-if="icon === 'addUser'" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.3985 13.4723C5.00089 13.4723 2.24658 10.718 2.24658 7.32038C2.24658 3.92277 5.00089 1.16846 8.3985 1.16846C11.7961 1.16846 14.5504 3.92277 14.5504 7.32038C14.5504 7.95525 14.4543 8.56766 14.2757 9.14384"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
      <path
        d="M9.61869 20.2702C9.97767 20.2702 10.2687 19.9792 10.2687 19.6202C10.2687 19.2612 9.97767 18.9702 9.61869 18.9702V20.2702ZM1.54926 18.1425C2.75875 16.3033 5.41028 14.1222 8.39843 14.1222V12.8222C4.81991 12.8222 1.81477 15.3727 0.46307 17.4283L1.54926 18.1425ZM2.27563 20.2702H9.61869V18.9702H2.27563V20.2702ZM0.46307 17.4283C0.0147946 18.11 0.0538107 18.8666 0.477393 19.4366C0.875507 19.9723 1.55438 20.2702 2.27563 20.2702V18.9702C1.89232 18.9702 1.63301 18.8122 1.52081 18.6612C1.43408 18.5445 1.39064 18.3838 1.54926 18.1425L0.46307 17.4283Z"
        :fill="color" />
      <path d="M16.4556 15.6494L12.7137 15.6494" :stroke="color" stroke-width="1.3" stroke-linecap="round" />
      <path d="M14.5504 17.5435L14.5504 13.8016" :stroke="color" stroke-width="1.3" stroke-linecap="round" />
      <circle cx="14.5504" cy="15.6724" r="3.84592" :stroke="color" stroke-width="1.3" />
    </svg>

    <svg v-if="icon === 'addGroup'" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5.22484" cy="3.86827" r="2.36304" :stroke="color" stroke-width="1.3" />
      <path
        d="M5.22491 8.16504C3.40693 8.16504 1.87377 13.6383 1.39872 17.3028C1.25672 18.3982 2.14201 19.2937 3.24658 19.2937H7.20323C8.3078 19.2937 9.19309 18.3982 9.05109 17.3028C8.57604 13.6383 7.04288 8.16504 5.22491 8.16504Z"
        :stroke="color"
        stroke-width="1.3" />
      <circle cx="13.9126" cy="3.86827" r="2.36304" :stroke="color" stroke-width="1.3" />
      <path
        d="M10.1931 10.8962C10.1533 10.6437 10.1178 10.3969 10.0867 10.1577C9.94464 9.06234 10.8298 8.16494 11.9343 8.16494L16.4621 8.16494C17.2512 8.16494 17.8873 8.80577 17.8052 9.59067V9.59067"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
      <path d="M17.4579 15.2253L13.6241 15.2253" :stroke="color" stroke-width="1.3" stroke-linecap="round" />
      <path d="M15.5059 17.1658L15.5059 13.332" :stroke="color" stroke-width="1.3" stroke-linecap="round" />
      <circle cx="15.5059" cy="15.2489" r="3.95632" :stroke="color" stroke-width="1.3" />
    </svg>

    <svg v-if="icon === 'editUser'" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2028 19.3279L16.9266 4.19434" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M15.4006 11.5284L17.3507 7.13934C17.3507 7.13934 17.9736 5.88554 16.5096 5.58472"
        :stroke="color"
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M9.50188 20.411L9.84259 19.2692C9.86767 19.1853 9.925 19.1149 10.002 19.0733C10.079 19.0318 10.1693 19.0225 10.2532 19.0475C10.337 19.0725 10.4075 19.1298 10.4491 19.2067C10.4908 19.2837 10.5001 19.3741 10.4752 19.4579C10.467 19.4849 10.4555 19.5108 10.4409 19.535L9.82191 20.5532C9.79962 20.5899 9.76473 20.6172 9.72377 20.63C9.68282 20.6428 9.63859 20.6403 9.59937 20.6229C9.56015 20.6054 9.52862 20.5743 9.51068 20.5353C9.49274 20.4964 9.48961 20.4522 9.50188 20.411Z"
        :fill="color" />
      <path
        d="M10.456 13.4552C9.79257 13.7002 9.07525 13.834 8.32668 13.834C4.9324 13.834 2.18079 11.0824 2.18079 7.68813C2.18079 4.29385 4.9324 1.54224 8.32668 1.54224C10.6693 1.54224 12.7058 2.85289 13.7428 4.78091"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
      <path
        d="M8.32663 13.834C5.04744 13.834 2.22254 16.1962 0.942688 18.1414C0.335557 19.0641 1.10722 19.976 2.21179 19.976H8.32663M12.4869 19.976H14.4415C15.546 19.976 16.3173 19.0633 15.7101 18.1406C15.3202 17.5482 14.787 16.9172 14.1414 16.326"
        :stroke="color"
        stroke-width="1.3"
        stroke-linecap="round" />
    </svg>

    <svg
      v-if="icon === 'withdrawalApprovals'"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.43359 12.5039L4.71094 10.8164C4.35938 10.5 4.35938 9.97266 4.71094 9.62109C5.02734 9.30469 5.55469 9.30469 5.90625 9.62109L7.03125 10.7461L9.80859 7.96875C10.125 7.61719 10.6523 7.61719 10.9688 7.96875C11.3203 8.28516 11.3203 8.8125 10.9688 9.12891L7.59375 12.5039C7.45312 12.6797 7.24219 12.75 7.03125 12.75C6.78516 12.75 6.57422 12.6797 6.43359 12.5039ZM15.4336 4.38281C15.6445 4.69922 15.75 5.08594 15.75 5.47266V13.875C15.75 15.1406 14.7305 16.125 13.5 16.125H2.25C0.984375 16.125 0 15.1406 0 13.875V5.47266C0 5.08594 0.0703125 4.69922 0.28125 4.38281L1.86328 1.53516C2.25 0.832031 3.02344 0.375 3.83203 0.375H11.8828C12.6914 0.375 13.4648 0.832031 13.8516 1.53516L15.4336 4.38281ZM8.68359 2.0625H8.64844V4.83984H13.7812L12.375 2.37891C12.2695 2.20312 12.0938 2.0625 11.8828 2.0625H8.68359ZM3.33984 2.37891L1.93359 4.83984H6.96094V2.0625H3.83203C3.62109 2.0625 3.44531 2.20312 3.33984 2.37891ZM14.0625 13.875V6.52734H1.6875V13.875C1.6875 14.1914 1.93359 14.4375 2.25 14.4375H13.5C13.7812 14.4375 14.0625 14.1914 14.0625 13.875Z"
        :fill="color" />
    </svg>

    <svg 
      v-if="icon === 'travelRule' || icon === 'travelRuleDark'"
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24"
    >
      <path :fill="color" d="M13,2V4C17.39,4.54 20.5,8.53 19.96,12.92C19.5,16.56 16.64,19.43 13,19.88V21.88C18.5,21.28 22.45,16.34 21.85,10.85C21.33,6.19 17.66,2.5 13,2M11,2C9.04,2.18 7.19,2.95 5.67,4.2L7.1,5.74C8.22,4.84 9.57,4.26 11,4.06V2.06M4.26,5.67C3,7.19 2.24,9.04 2.05,11H4.05C4.24,9.58 4.8,8.23 5.69,7.1L4.26,5.67M2.06,13C2.26,14.96 3.03,16.81 4.27,18.33L5.69,16.9C4.81,15.77 4.24,14.42 4.06,13H2.06M7.06,18.37L5.67,19.74C7.18,21 9.04,21.79 11,22V20C9.58,19.82 8.23,19.25 7.1,18.37H7.06M13,13V7H11V13H13M13,17V15H11V17H13Z" />
    </svg>
  </div>
</template>
<script>
export default {
  name: "CustomIcon",
  props: ["icon", "dark"],
  computed: {
    color() {
      return this.dark ? "#737373" : "white";
    },
  },
};
</script>
