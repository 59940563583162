<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="pb-0" style="font-size: 14px; color: #348bde">
        <span
          @click="$router.push('/businesses').catch((err) => {})"
          style="text-decoration: underline; cursor: pointer"
          >Businesses</span
        >
        <span class="ml-1">{{ `/` }}</span>
        <span
          @click="
            $router
              .push({
                name: 'business',
                params: { id: generatePayload.id },
              })
              .catch((err) => {})
          "
          style="text-decoration: underline; cursor: pointer"
          >{{ ` ${businessName}` }}</span
        >
        <span class="ml-1" style="font-weight: 500">{{ ` / Edit business` }}</span>
      </v-col>
      <v-col cols="12" class="d-flex mb-10">
        <v-icon class="mr-2">mdi-briefcase-edit</v-icon>&nbsp;
        <v-flex>{{ ` ${businessName}` }}</v-flex>
      </v-col>
      <v-col cols="12" md="12" sm="12" xs="12" lg="12" style="max-width: 600px">
        <MaterialCard title="Edit business" :text="generateTitle">
          <v-form>
            <v-container class="py-0">
              <v-row justify="center" v-show="!showOverview && !editFinished">
                <v-col cols="12" md="10">
                  <v-text-field
                    id="new-pass"
                    autocomplete="none"
                    :color="$AppConfig.setTheme().primaryColor"
                    v-validate="{ required: true }"
                    name="businessName"
                    :rules="!errors.first('businessName') ? [true] : [errors.first('businessName')]"
                    :error-messages="errors.collect('businessName')"
                    label="Business name"
                    class="input-group--focused"
                    v-model="businessName"
                    :append-icon="'mdi-pencil'"></v-text-field>
                </v-col>
                <v-col cols="12" md="10" class="d-flex align-center">
                  <v-autocomplete
                    :color="$AppConfig.setTheme().primaryColor"
                    label="Supported display currencies"
                    :items="supportedFiatCurrencies"
                    v-model="selectedFiatCurrencies"
                    chips
                    clearable
                    multiple
                    :deletable-chips="Boolean(true)"
                    :menu-props="{ auto: true, overflowY: true }">
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <v-chip v-if="index === 1">
                        <span>{{ item }}</span>
                      </v-chip>
                      <v-chip v-if="index === 2">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 2" class="grey--text caption">
                        (+{{ selectedFiatCurrencies.length - 3 }} others)
                      </span>
                    </template>
                  </v-autocomplete>
                  <v-btn
                    v-if="selectedFiatCurrencies.length !== supportedFiatCurrencies.length"
                    class="ml-4"
                    text
                    :color="$AppConfig.setTheme().primaryColor"
                    @click="addAllFiatCurrencies"
                    >All
                  </v-btn>
                </v-col>
                <v-col cols="12" md="10" class="d-flex flex-wrap mb-5">
                  <span>Supported deposit currencies and networks</span>
                  <div style="color: #000000; font-weight: 500; width: 100%" class="mt-2">
                    {{
                      Object.keys(this.selectedCryptoCurrenciesWithNetworks)
                        .map((currency) => {
                          return `${currency}(${this.selectedCryptoCurrenciesWithNetworks[currency]
                            .toString()
                            .replace(/,/g, ", ")})`;
                        })
                        .toString()
                        .replace(/,/g, ", ")
                    }}
                  </div>
                  <v-btn
                    v-if="!overlay"
                    width="120"
                    class="mt-2"
                    dark
                    :color="$AppConfig.setTheme().positiveButtonAction"
                    @click="editDepositCurrenciesAndNetworksModal = true">
                    Configure
                  </v-btn>
                </v-col>

                <v-col cols="12" md="10">
                  <v-autocomplete 
                    :color="$AppConfig.setTheme().primaryColor"
                    dense
                    item-text="text"
                    v-model="settlementCurrency"
                    :items="enabledSettlementCurrencies"
                    persistent-hint
                    label="Crypto deposit settlement currency"
                    disabled
                  />
                </v-col>

                <v-col cols="12" md="10">
                  <v-text-field
                    :color="$AppConfig.setTheme().primaryColor"
                    autocomplete="off"
                    v-validate.lazy="{
                      regex: /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/,
                    }"
                    :rules="!errors.first('depositCallback') ? [true] : [errors.first('depositCallback')]"
                    :error-messages="errors.collect('depositCallback')"
                    name="depositCallback"
                    label="Deposit callback URL"
                    class="input-group--focused"
                    v-model="depositURL"
                    :append-icon="'mdi-pencil'"
                    hint="Optional">
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="10">
                  <v-text-field
                    :color="$AppConfig.setTheme().primaryColor"
                    autocomplete="off"
                    v-validate.lazy="{
                      regex: /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/,
                    }"
                    :rules="!errors.first('withdrawalCallback') ? [true] : [errors.first('withdrawalCallback')]"
                    :error-messages="errors.collect('withdrawalCallback')"
                    name="withdrawalCallback"
                    label="Withdrawal callback URL"
                    class="input-group--focused"
                    v-model="withdrawalURL"
                    :append-icon="'mdi-pencil'"
                    hint="Optional"></v-text-field>
                </v-col>
                <v-col cols="12" md="10">
                  <v-text-field
                    :color="$AppConfig.setTheme().primaryColor"
                    autocomplete="off"
                    v-validate.lazy="{
                      regex: /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/,
                    }"
                    :rules="
                      !errors.first('withdrawalApprovalCallback')
                        ? [true]
                        : [errors.first('withdrawalApprovalCallback')]
                    "
                    :error-messages="errors.collect('withdrawalApprovalCallback')"
                    name="withdrawalApprovalCallback"
                    label="Withdrawal approval callback URL"
                    class="input-group--focused"
                    v-model="withdrawalApprovalURL"
                    :append-icon="'mdi-pencil'"
                    hint="Optional"></v-text-field>
                </v-col>
                <v-col cols="12" md="10">
                  <v-checkbox
                    :color="$AppConfig.setTheme().primaryColor"
                    hint="In case the user sends more than the requested amount, the excess amount will be refunded back to the user."
                    persistent-hint
                    label="Refund overpaid amount"
                    v-model="refundOverpaidAmount">
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="10">
                  <v-checkbox
                    :color="$AppConfig.setTheme().primaryColor"
                    hint="For One-time payments, any follow-up deposits will not be processed and will be refunded back to the user."
                    persistent-hint
                    label="Refund follow-up transactions"
                    v-model="refundFollowUpTransactions"
                    :disabled="refundOverpaidAmount">
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="10">
                  <v-radio-group v-model="blockchainFeePaidBy">
                    <v-radio :value="'USER'" :color="$AppConfig.setTheme().primaryColor">
                      <template v-slot:label>
                        <div class="ml-1">
                          End-user covers crypto withdrawal onchain fees
                          <div class="text-caption">The fee will be deducted from the amount sent to the end-user</div>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="'MERCHANT'" :color="$AppConfig.setTheme().primaryColor">
                      <template v-slot:label>
                        <div class="ml-1">
                          Merchant covers crypto withdrawal onchain fees
                          <div class="text-caption">The fee will be deducted from the business' balance</div>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>

                <OptionalFeaturesConfig
                  :oneToOneRatesEnabled="oneToOneRatesEnabled"
                  :stablecoinDepegThreshold="stablecoinDepegThreshold"
                  :canAdjustFeeDistribution="canAdjustFeeDistribution"
                  :feeDistributionEnabled="feeDistributionEnabled"
                  :depositFeeDistribution="depositFeeDistributionPercentage"
                  :feeDistributionLimit="feeDistributionLimit"
                  @updateOneToOneRatesEnabled="oneToOneRatesEnabled = $event"
                  @updateStablecoinDepegThreshold="stablecoinDepegThreshold = $event"
                  @updateFeeDistributionEnabled="feeDistributionEnabled = $event"
                  @updateDepositFeeDistribution="depositFeeDistributionPercentage = $event" />

                <v-col cols="12" md="10" v-if="$AppConfig.showInfoSections">
                  <v-alert text type="info" color="info" class="mt-4">
                    <div style="font-size: 14px">
                      Learn more about 
                      <a href="https://docs.finrax.com/references/callbacks" target="_blank"> callback URLs</a>
                    </div>
                  </v-alert>
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn width="120" dark :color="$AppConfig.setTheme().positiveButtonAction" @click="verify">
                    Verify
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showOverview && !editFinished">
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead></thead>
                      <tbody>
                        <tr v-for="(item, index) in finalOverview" :key="item.value + index">
                          <td>{{ item.name }}</td>
                          <td>
                            {{ item.value }}&nbsp;
                            <v-icon v-if="item.hasChanged" color="info">mdi-pencil</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>

                <v-col cols="12">
                  <v-alert type="info" text color="info" class="mt-4">
                    <div style="font-size: 14px">The pencil icon indicates that this option has been modified.</div>
                  </v-alert>
                </v-col>
                <v-col cols="12" class="text-right mt-4">
                  <v-btn width="120" text dark :color="$AppConfig.setTheme().primaryColor" @click="showOverview = false"
                    >Back
                  </v-btn>
                  <v-btn
                    class="ml-4"
                    width="120"
                    dark
                    :color="$AppConfig.setTheme().positiveButtonAction"
                    @click="submit">
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="editFinished">
                <v-col cols="12">
                  <v-alert text type="success" color="success" class="mt-0">
                    <h4 class="mb-4">Business updated successfully!</h4>
                  </v-alert>
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn dark :color="$AppConfig.setTheme().primaryColor" @click="goToPayments"
                    >Go to businesses
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </MaterialCard>
      </v-col>
    </v-row>
    <v-overlay z-index="200" opacity=".7" :value="overlay">
      <v-progress-circular indeterminate size="64">
        <ResponseNotifier v-if="success || failure" :done="success" :failed="failure" :message="responseMessage" />
      </v-progress-circular>
    </v-overlay>
    <Snackbar :config="snackbarConfig" />
    <v-dialog :persistent="true" v-model="editDepositCurrenciesAndNetworksModal" max-width="680">
      <v-card class="d-flex flex-column align-center pa-3">
        <DepositCurrenciesNetworksConfiguration
          v-if="enabledCryptoCurrencies.length"
          @depositCurrenciesWithNetworksSelected="(val) => (selectedCryptoCurrenciesWithNetworks = val)"
          :preselectedDepositCurrenciesAndNetworks="selectedCryptoCurrenciesWithNetworks"
          :supported-deposit-currencies="enabledCryptoCurrencies" />
        <div class="d-flex justify-end" style="width: 100%">
          <v-btn :color="$AppConfig.setTheme().primaryColor" text @click="closeModal">Save</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ResponseNotifier from "../Common/ResponseNotifier";
import MaterialCard from "../Common/MaterialCard";
import { mapGetters } from "vuex";
import Business from "../../services/api/business";
import { sortFiat } from "@/services/sortCurrencies";
import Snackbar from "@/components/Common/Snackbar.vue";
import { getErrorMessage } from "@/services/errMessageGenerator";
import Payment from "@/services/api/payment";
import DepositCurrenciesNetworksConfiguration from "@/components/Business/DepositCurrenciesNetworksConfiguration.vue";
import OptionalFeaturesConfig from "@/components/Business/OptionalFeaturesConfig.vue";

export default {
  components: {
    Snackbar,
    MaterialCard,
    ResponseNotifier,
    DepositCurrenciesNetworksConfiguration,
    OptionalFeaturesConfig,
  },
  name: "EditBusiness",
  data() {
    return {
      editableBusiness: "",
      businessName: "",
      selectedFiatCurrencies: [],
      supportedFiatCurrencies: [],
      enabledCryptoCurrencies: [],
      enabledSettlementCurrencies: [],
      settlementCurrency: null,
      selectedCryptoCurrenciesWithNetworks: {},
      depositURL: "",
      withdrawalURL: "",
      withdrawalApprovalURL: "",
      refundOverpaidAmount: false,
      refundFollowUpTransactions: false,
      blockchainFeePaidBy: "USER",
      canAdjustFeeDistribution: false,
      feeDistributionEnabled: false,
      feeDistributionLimit: 0,
      depositFeeDistributionPercentage: 0,
      oneToOneRatesEnabled: false,
      stablecoinDepegThreshold: 0,
      finalOverview: [],
      snackbarConfig: { color: "error", text: "", snackbar: false },
      overlay: false,
      responseMessage: "",
      success: false,
      failure: false,
      showOverview: false,
      editFinished: false,
      editDepositCurrenciesAndNetworksModal: false,
    };
  },
  computed: {
    ...mapGetters(["getAllDisplayCurrencies", "cryptoCoins"]),
    generateTitle() {
      if (this.showOverview && !this.editFinished) {
        return "Submit changes";
      }
      if (!this.showOverview && !this.editFinished) {
        return "Change settings";
      }
      if (this.editFinished) return "Update completed";
    },
    existingStablecoinDepegThreshold() {
      if (!this.editableBusiness.stableCryptoCurrencyDepegThreshold) {
        return 0;
      }

      return parseFloat(this.editableBusiness.stableCryptoCurrencyDepegThreshold) * 100;
    },
    generatePayload() {
      let payload = {
        id: this.editableBusiness.id,
        businessName: this.businessName,
        supportedDisplayCurrencies: this.selectedFiatCurrencies,
        supportedDepositCurrenciesWithNetworks: this.selectedCryptoCurrenciesWithNetworks,
        blockchainFeePaidBy: this.blockchainFeePaidBy,
        stableCryptoCurrencyDepegThreshold: this.stablecoinDepegThreshold ? this.stablecoinDepegThreshold / 100 : null,
        userServiceFeeDistributionPercentage: this.canAdjustFeeDistribution ? this.depositFeeDistributionPercentage : 0,
        overpaymentPolicy: this.$AppConfig.getOverpayScenario(this.refundOverpaidAmount),
        refundFollowUpDepositsForOneTimePayments: this.refundFollowUpTransactions,
      };
      if (this.depositURL !== "") {
        payload.depositReceivedCallbackUrl = this.depositURL;
      }
      if (this.withdrawalURL !== "") {
        payload.withdrawalCallbackUrl = this.withdrawalURL;
      }
      if (this.withdrawalApprovalURL !== "") {
        payload.withdrawalApprovalCallbackUrl = this.withdrawalApprovalURL;
      }

      return payload;
    },
  },
  watch: {
    refundOverpaidAmount: {
      immediate: true,
      handler(val) {
        if (val) return (this.refundFollowUpTransactions = true);
      },
    },
    "$route.params": {
      immediate: true,
      async handler() {
        await this.fetchOrganisationMetadata();
        await this.getSingleBusiness();
      },
    },
    showOverview() {
      this.finalOverview = [];
      this.finalOverview.push({
        name: "Business name:",
        value: `${this.businessName}`,
        hasChanged: this.businessName !== this.editableBusiness.name,
      });

      if (this.editableBusiness.supportedDisplayCurrencies.length) {
        this.finalOverview.push({
          name: "Number of display currencies:",
          value: `${this.selectedFiatCurrencies.length}`,
          hasChanged: this.selectedFiatCurrencies.length !== this.editableBusiness.supportedDisplayCurrencies.length,
        });
      }
      if (Object.keys(this.editableBusiness.supportedDepositCurrenciesWithNetworks).length) {
        this.finalOverview.push({
          name: "Number of deposit currencies with networks:",
          value: `${Object.keys(this.selectedCryptoCurrenciesWithNetworks).length}`,
          hasChanged: !(
            JSON.stringify(this.selectedCryptoCurrenciesWithNetworks) ===
            JSON.stringify(this.editableBusiness.supportedDepositCurrenciesWithNetworks)
          ),
        });
      }
      this.finalOverview.push(
        {
          name: "Deposit callback URL:",
          value: `${this.depositURL || "None"}`,
          hasChanged: (this.depositURL || "").localeCompare(this.editableBusiness.depositReceivedCallbackUrl || ""),
        },
        {
          name: "Withdrawal callback URL:",
          value: `${this.withdrawalURL || "None"}`,
          hasChanged: (this.withdrawalURL || "").localeCompare(this.editableBusiness.withdrawalCallbackUrl || ""),
        },
        {
          name: "Withdrawal approval callback URL:",
          value: `${this.withdrawalApprovalURL || "None"}`,
          hasChanged: (this.withdrawalApprovalURL || "").localeCompare(
            this.editableBusiness.withdrawalApprovalCallbackUrl || ""
          ),
        },
        {
          name: "Refund overpaid amount:",
          value: this.refundOverpaidAmount ? "Yes" : "No",
          hasChanged:
            this.$AppConfig.getOverpayScenario(this.refundOverpaidAmount) !== this.editableBusiness.overpaymentPolicy,
        },
        {
          name: "Refund follow-up transactions:",
          value: this.refundFollowUpTransactions ? "Yes" : "No",
          hasChanged:
            this.refundFollowUpTransactions !== this.editableBusiness.refundFollowUpDepositsForOneTimePayments,
        },
        {
          name: "Blockchain fee covered by:",
          value: this.blockchainFeePaidBy === "USER" ? "End-user" : "Merchant", // TODO: Use switch when rewriting
          hasChanged: this.blockchainFeePaidBy !== this.editableBusiness.blockchainFeePaidBy,
        },
        {
          name: "Stablecoin depeg threshold:",
          value: `${this.stablecoinDepegThreshold}%`,
          hasChanged: this.stablecoinDepegThreshold !== this.existingStablecoinDepegThreshold,
        },
        ...(this.canAdjustFeeDistribution
          ? [
              {
                name: "Distribute fee to users",
                value: `${this.depositFeeDistributionPercentage}%`,
                hasChanged:
                  this.depositFeeDistributionPercentage !==
                  parseFloat(this.editableBusiness.userServiceFeeDistributionPercentage),
              },
            ]
          : [])
      );
    },
    oneToOneRatesEnabled() {
      if (this.oneToOneRatesEnabled) {
        this.feeDistributionEnabled = false;
      } else {
        this.stablecoinDepegThreshold = 0;
      }
    },
    feeDistributionEnabled() {
      if (this.feeDistributionEnabled) {
        this.oneToOneRatesEnabled = false;
      } else {
        this.depositFeeDistributionPercentage = 0;
      }
    },
  },
  methods: {
    closeModal() {
      if (Object.keys(this.selectedCryptoCurrenciesWithNetworks).length) {
        this.editDepositCurrenciesAndNetworksModal = false;
      }
    },
    async getSingleBusiness() {
      this.overlay = true;

      try {
        const allBusinesses = await Business.getAll();

        if (allBusinesses.length) {
          this.editableBusiness = allBusinesses.filter((business) => business.id === this.$route.params.id)[0];
          this.settlementCurrency = this.editableBusiness.settlementCurrency;
          this.businessName = this.editableBusiness.name;
          this.depositURL = this.editableBusiness.depositReceivedCallbackUrl || "";
          this.withdrawalURL = this.editableBusiness.withdrawalCallbackUrl || "";
          this.withdrawalApprovalURL = this.editableBusiness.withdrawalApprovalCallbackUrl || "";
          this.stablecoinDepegThreshold = this.editableBusiness.stableCryptoCurrencyDepegThreshold
            ? parseFloat(this.editableBusiness.stableCryptoCurrencyDepegThreshold * 100)
            : 0;
          this.oneToOneRatesEnabled = this.stablecoinDepegThreshold > 0;
          this.depositFeeDistributionPercentage = parseFloat(
            this.editableBusiness.userServiceFeeDistributionPercentage
          );
          this.feeDistributionEnabled = this.depositFeeDistributionPercentage > 0;
          this.selectedFiatCurrencies = sortFiat(this.editableBusiness.supportedDisplayCurrencies);
          this.selectedCryptoCurrenciesWithNetworks = this.editableBusiness.supportedDepositCurrenciesWithNetworks;
          let fiats = this.getAllDisplayCurrencies.map((currency) => currency.abbreviation);
          this.supportedFiatCurrencies = sortFiat(fiats);
          this.refundOverpaidAmount =
            this.editableBusiness.overpaymentPolicy === this.$AppConfig.overpayScenarios.refund;
          this.refundFollowUpTransactions = this.editableBusiness.refundFollowUpDepositsForOneTimePayments;
          this.blockchainFeePaidBy = this.editableBusiness.blockchainFeePaidBy;
        }
      } catch (e) {
        this.snackbarConfig = { color: "error", text: getErrorMessage(e, "Couldn't load businesses"), snackbar: true };
      } finally {
        this.overlay = false;
      }
    },
    async fetchOrganisationMetadata() {
      this.overlay = true;

      try {
        const metadata = await Payment.fetchOrganisationMetadata();
        const { enabledFiatSettlementCurrencies, enabledCryptoSettlementCurrencies } = metadata;
        const sortedEnabledFiatSettlementCurrencies = enabledFiatSettlementCurrencies.sort((a, b) => a.localeCompare(b));
        const sortedEnabledCryptoSettlementCurrencies = [...enabledCryptoSettlementCurrencies, 'USDT'].sort((a, b) => a.localeCompare(b));

        this.enabledCryptoCurrencies = this.cryptoCoins.filter(coin => metadata.enabledCryptoCurrencies.includes(coin.abbreviation)); 
        this.enabledSettlementCurrencies = [...sortedEnabledFiatSettlementCurrencies, ...sortedEnabledCryptoSettlementCurrencies];
        this.canAdjustFeeDistribution = metadata.allowServiceFeeDistribution;
        this.feeDistributionLimit = metadata.feeDistributionLimit * 100;
      } catch (e) {
        this.snackbarConfig = {
          color: "error",
          text: getErrorMessage(e, "Couldn't load business metadata"),
          snackbar: true,
        };
      } finally {
        this.overlay = false;
      }
    },
    addAllFiatCurrencies() {
      this.selectedFiatCurrencies = this.supportedFiatCurrencies;
    },
    goToPayments() {
      this.$router.push("/businesses").catch((err) => {});
    },
    verify() {
      if (!this.selectedFiatCurrencies.length || !Object.keys(this.selectedCryptoCurrenciesWithNetworks).length) {
        this.$validator.validate();
        this.snackbarConfig = { color: "error", text: "Please fill in all required fields", snackbar: true };
      } else {
        this.$validator.validate().then((res) => (res ? (this.showOverview = true) : ""));
      }
    },
    submit() {
      this.$validator.validate().then((res) => {
        if (res) {
          this.overlay = true;
          Business.update(this.generatePayload)
            .then((res) => {
              this.editFinished = true;
              this.overlay = false;
            })
            .catch((err) => {
              this.responseMessage = err.message || err.rejection;
              this.failure = true;
              setTimeout(() => {
                this.failure = false;
                this.overlay = false;
              }, 3000);
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
</style>
