import axiosService from "../axios.service"

class TravelRuleService {
    async fetchDepositsWithoutTravelRuleData() {
        const response = await axiosService.get(`${process.env.VUE_APP_URI}deposits/travel-rule`)
        return response.data
    }

    /**
     * @param {string} depositId
     * @param {{
     *  entityType: string; 
     *  personalIdentityName: string; 
     *  nameIdentifierType: string; 
     *  personalIdentifier: string; 
     *  nationalIdentityType: string; 
     * }} payload 
     */
    async updateDepositTravelRuleData(depositId, payload) {
        return axiosService.patch(`${process.env.VUE_APP_URI}deposits/travel-rule/${depositId}`, {
            originator: payload
        })
    }
}

export default new TravelRuleService()