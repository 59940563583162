import Vue from "vue";
import Vuetify from "vuetify/lib";

import CustomIcon from "@/components/Common/CustomIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      stats: { component: CustomIcon, props: { icon: "stats" } },
      statsDark: { component: CustomIcon, props: { icon: "stats", dark: true } },

      businesses: { component: CustomIcon, props: { icon: "businesses" } },
      businessesDark: { component: CustomIcon, props: { icon: "businesses", dark: true } },

      bank: { component: CustomIcon, props: { icon: "bank" } },
      bankDark: { component: CustomIcon, props: { icon: "bank", dark: true } },

      whitelistedAddresses: { component: CustomIcon, props: { icon: "whitelistedAddresses" } },
      whitelistedAddressesDark: { component: CustomIcon, props: { icon: "whitelistedAddresses", dark: true } },

      cryptoDeposit: { component: CustomIcon, props: { icon: "cryptoDeposit" } },
      cryptoDepositDark: { component: CustomIcon, props: { icon: "cryptoDeposit", dark: true } },

      cryptoWithdrawal: { component: CustomIcon, props: { icon: "cryptoWithdrawal" } },
      cryptoWithdrawalDark: { component: CustomIcon, props: { icon: "cryptoWithdrawal", dark: true } },

      fiatDeposit: { component: CustomIcon, props: { icon: "fiatDeposit" } },
      fiatDepositDark: { component: CustomIcon, props: { icon: "fiatDeposit", dark: true } },

      fiatWithdrawal: { component: CustomIcon, props: { icon: "fiatWithdrawal" } },
      fiatWithdrawalDark: { component: CustomIcon, props: { icon: "fiatWithdrawal", dark: true } },

      automaticWithdrawal: { component: CustomIcon, props: { icon: "automaticWithdrawal" } },
      automaticWithdrawalDark: { component: CustomIcon, props: { icon: "automaticWithdrawal", dark: true } },

      internalTransfer: { component: CustomIcon, props: { icon: "internalTransfer" } },
      internalTransferDark: { component: CustomIcon, props: { icon: "internalTransfer", dark: true } },

      balanceConversion: { component: CustomIcon, props: { icon: "balanceConversion" } },
      balanceConversionDark: { component: CustomIcon, props: { icon: "balanceConversion", dark: true } },

      wallet: { component: CustomIcon, props: { icon: "wallet" } },
      walletDark: { component: CustomIcon, props: { icon: "wallet", dark: true } },

      ledger: { component: CustomIcon, props: { icon: "ledger" } },
      ledgerDark: { component: CustomIcon, props: { icon: "ledger", dark: true } },

      lock: { component: CustomIcon, props: { icon: "lock" } },
      lockDark: { component: CustomIcon, props: { icon: "lock", dark: true } },

      fingerprint: { component: CustomIcon, props: { icon: "fingerprint" } },
      fingerprintDark: { component: CustomIcon, props: { icon: "fingerprint", dark: true } },

      api: { component: CustomIcon, props: { icon: "api" } },
      apiDark: { component: CustomIcon, props: { icon: "api", dark: true } },

      user: { component: CustomIcon, props: { icon: "user" } },
      userDark: { component: CustomIcon, props: { icon: "user", dark: true } },

      addUser: { component: CustomIcon, props: { icon: "addUser" } },
      addUserDark: { component: CustomIcon, props: { icon: "addUser", dark: true } },

      editUser: { component: CustomIcon, props: { icon: "editUser" } },
      editUserDark: { component: CustomIcon, props: { icon: "editUser", dark: true } },

      group: { component: CustomIcon, props: { icon: "group" } },
      groupDark: { component: CustomIcon, props: { icon: "group", dark: true } },

      addGroup: { component: CustomIcon, props: { icon: "addGroup" } },
      addGroupDark: { component: CustomIcon, props: { icon: "addGroup", dark: true } },

      withdrawalApprovals: { component: CustomIcon, props: { icon: "withdrawalApprovals" } },
      withdrawalApprovalsDark: { component: CustomIcon, props: { icon: "withdrawalApprovals", dark: true } },

      travelRule: { component: CustomIcon, props: { icon: 'travelRule', } },
      travelRuleDark: { component: CustomIcon, props: { icon: 'travelRule', dark: true, } },
    },
  },
});
