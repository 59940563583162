<template>
  <div>
    <v-row justify="center" v-if="!load">
      <v-col cols="12" class="pb-0" style="font-size: 14px; color: #348bde">
        <span
          @click="$router.push('/businesses').catch((err) => {})"
          style="text-decoration: underline; cursor: pointer"
          >Businesses</span
        >
        <span class="ml-1" style="font-weight: 500">
          {{ ` / ${generateTitle ? generateTitle.name : ""}` }}
        </span>
      </v-col>
      <v-col cols="12" class="d-flex mb-10 flex-wrap">
        <v-icon class="mr-2">$vuetify.icons.businessesDark</v-icon>&nbsp;
        <v-flex>{{ generateTitle ? generateTitle.name : "" }}</v-flex>
        <v-flex style="width: 100%" class="mt-1">ID:{{ generateTitle ? generateTitle.id : "" }}</v-flex>
      </v-col>
      <v-col></v-col>

      <v-col cols="12" md="12">
        <v-col cols="12" md="12" class="d-flex justify-center align-self-center">
          <v-item-group
            v-model="selectedTabIndex"
            :mandatory="true"
            :multiple="false"
            class="d-flex justify-center flex-wrap">
            <v-item
              v-slot:default="{ active, toggle }"
              v-if="userPermissions && userPermissions.includes('GET_PAYMENT')">
              <v-btn
                style="text-transform: capitalize"
                :color="$AppConfig.setTheme().primaryColor"
                class="d-flex"
                height="40"
                dark
                @click="toggle"
                v-on:click="changeRoute"
                :text="!active"
                min-width="124">
                <div>Crypto Deposits</div>
              </v-btn>
            </v-item>
            <v-item
              v-slot:default="{ active, toggle }"
              v-if="userPermissions && userPermissions.includes('GET_WITHDRAWALS')">
              <v-btn
                style="text-transform: capitalize"
                :color="$AppConfig.setTheme().primaryColor"
                :text="!active"
                class="d-flex"
                height="40"
                dark
                @click="toggle"
                v-on:click="changeRoute"
                min-width="124">
                <div>Crypto Withdrawals</div>
              </v-btn>
            </v-item>
            <v-item
              v-slot:default="{ active, toggle }"
              v-if="userPermissions && userPermissions.includes('GET_FIAT_DEPOSITS')">
              <v-btn
                style="text-transform: capitalize"
                :color="$AppConfig.setTheme().primaryColor"
                :text="!active"
                class="d-flex align-center wrap"
                height="40"
                dark
                @click="toggle"
                v-on:click="changeRoute"
                min-width="124">
                <div>Fiat Deposits</div>
              </v-btn>
            </v-item>
            <v-item
              v-slot:default="{ active, toggle }"
              v-if="userPermissions && userPermissions.includes('GET_FIAT_WITHDRAWALS')">
              <v-btn
                style="text-transform: capitalize"
                :color="$AppConfig.setTheme().primaryColor"
                :text="!active"
                class="d-flex align-center wrap"
                height="40"
                dark
                @click="toggle"
                v-on:click="changeRoute"
                min-width="124">
                <div>Fiat Withdrawals</div>
              </v-btn>
            </v-item>
            <v-item
              v-slot:default="{ active, toggle }"
              v-if="userPermissions && userPermissions.includes('GET_INTERNAL_TRANSFERS')">
              <v-btn
                style="text-transform: capitalize"
                :color="$AppConfig.setTheme().primaryColor"
                :text="!active"
                class="d-flex align-center wrap"
                height="40"
                dark
                @click="toggle"
                v-on:click="changeRoute"
                min-width="124">
                <div>Internal Transfers</div>
              </v-btn>
            </v-item>
            <v-item
              v-slot:default="{ active, toggle }"
              v-if="userPermissions && userPermissions.includes('GET_INTERNAL_TRANSFERS')">
              <v-btn
                style="text-transform: capitalize"
                :color="$AppConfig.setTheme().primaryColor"
                :text="!active"
                class="d-flex align-center wrap"
                height="40"
                dark
                @click="toggle"
                v-on:click="changeRoute"
                min-width="124">
                <div>Unassociated Deposits</div>
              </v-btn>
            </v-item>
            <v-item
              v-slot:default="{ active, toggle }"
              v-if="userPermissions && userPermissions.includes('FETCH_MANUAL_MOVEMENTS')">
              <v-btn
                style="text-transform: capitalize"
                :color="$AppConfig.setTheme().primaryColor"
                :text="!active"
                class="d-flex align-center wrap"
                height="40"
                dark
                @click="toggle"
                v-on:click="changeRoute"
                min-width="124">
                <div>Manual Operations</div>
              </v-btn>
            </v-item>
            <v-item
              v-slot:default="{ active, toggle }"
              v-if="userPermissions && userPermissions.includes('GET_BALANCE_CONVERSIONS')">
              <v-btn
                style="text-transform: capitalize"
                :color="$AppConfig.setTheme().primaryColor"
                :text="!active"
                class="d-flex align-center wrap"
                height="40"
                dark
                @click="toggle"
                v-on:click="changeRoute"
                min-width="124">
                <div>Balance conversions</div>
              </v-btn>
            </v-item>
          </v-item-group>
        </v-col>
        <router-view
          :isMobile="isMobile"
          :mobileBreakpoint="mobileBreakpoint"
          :businessName="businessName"
          :supportedDepositCurrencies="supportedDepositCurrencies"></router-view>
      </v-col>
    </v-row>
    <Snackbar :config="snackbarConfig" />
    <v-overlay absolute z-index="222" opacity=".7" :value="load">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Snackbar from "../Common/Snackbar";
import { mapActions, mapState } from "vuex";
import { ACTION_CHOSEN_BUSINESS } from "@/store/types";
import Business from "../../services/api/business";
import { TokenService } from "@/services/storage.service";
import { getErrorMessage } from "@/services/errMessageGenerator";

export default {
  name: "Business",
  components: {
    Snackbar,
  },
  data() {
    return {
      load: true,
      paymentTypes: [
        {
          permission: "GET_PAYMENT",
          route: this.$AppConfig.paymentType.DEPOSITS,
        },
        {
          permission: "GET_WITHDRAWALS",
          route: this.$AppConfig.paymentType.WITHDRAWALS,
        },
        {
          permission: "GET_FIAT_DEPOSITS",
          route: this.$AppConfig.paymentType.FIAT_TOP_UPS,
        },
        {
          permission: "GET_FIAT_WITHDRAWALS",
          route: this.$AppConfig.paymentType.SETTLEMENTS,
        },
        {
          permission: "GET_INTERNAL_TRANSFERS",
          route: this.$AppConfig.paymentType.TRANSFERS,
        },
        {
          permission: "GET_INTERNAL_TRANSFERS",
          route: this.$AppConfig.paymentType.UNASSOCIATED_DEPOSITS,
        },
        {
          permission: "FETCH_MANUAL_MOVEMENTS",
          route: this.$AppConfig.paymentType.MANUAL_LEDGER_MOVEMENTS,
        },
        {
          permission: "GET_BALANCE_CONVERSIONS",
          route: this.$AppConfig.paymentType.BALANCE_CONVERSIONS,
        },
        {
          permission: "GET_BALANCES",
          route: this.$AppConfig.paymentType.STATISTICS,
        },
      ],
      availableTabs: [],
      selectedTabIndex: null,
      snackbarConfig: { text: "", color: "", snackbar: false },
      allBusinesses: [],
      windowWidth: window.innerWidth,
      mobileBreakpoint: 960,
    };
  },
  computed: {
    ...mapState({
      userPermissions: (state) => state.user.permissions,
      selectedBusinessID: (state) => state.business.chosenBusiness,
    }),
    generateTitle() {
      return this.allBusinesses.filter((business) => business.id === this.selectedBusinessID)[0];
    },
    businessName() {
      return this.allBusinesses.filter((item) => item.id === this.$route.params.id)[0].name;
    },
    supportedDepositCurrencies() {
      if (this.allBusinesses.length) {
        const business = this.allBusinesses.filter((business) => business.id === this.$route.params.id)[0];
        return business ? business.supportedDepositCurrencies : [];
      }
    },
    isMobile() {
      return this.windowWidth > this.mobileBreakpoint;
    },
  },
  methods: {
    ...mapActions({
      selectedBusiness: ACTION_CHOSEN_BUSINESS,
    }),
    changeRoute() {
      this.$nextTick(() => this.$router.push({ name: `business-${this.availableTabs[this.selectedTabIndex]}` }));
    },
    populateTabs() {
      //needs to be taken from TokenService as vuex store might not be available yet
      const permissionsFromStorage = TokenService.getConfig("permissions");

      this.availableTabs = [];
      this.paymentTypes.forEach((type) =>
        this.availableTabs.push(...(permissionsFromStorage.includes(type.permission) ? [type.route] : []))
      );
    },
    setTabBasedOnPermissions() {
      //needs to be taken from TokenService as vuex store might not be available yet
      const permissionsFromStorage = TokenService.getConfig("permissions");
      const firstAvailablePaymentType = this.paymentTypes.find((type) =>
        permissionsFromStorage.includes(type.permission)
      );

      this.selectedTabIndex = this.availableTabs.indexOf(firstAvailablePaymentType.route);
      return this.changeRoute();
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  async created() {
    try {
      this.allBusinesses = await Business.getAll();
      let selectBusiness = this.allBusinesses.filter((business) => business.id === this.$route.params.id)[0];
      this.selectedBusiness({
        app: selectBusiness,
        isCached: true,
        id: this.$route.params.id,
      });
    } catch (e) {
      this.snackbarConfig = {
        text: getErrorMessage(e, "Failed to load businesses"),
        color: "error",
        snackbar: true,
      };
    } finally {
      this.load = false;
    }
  },
  mounted() {
    this.populateTabs();
    //set tab manually if user navigated here from the business list
    if (this.$router.currentRoute.matched.length === 1) {
      this.setTabBasedOnPermissions();
    } else {
      const paymentTypeToToggle = this.$router.currentRoute.matched[1].name.split("-")[1];
      this.selectedTabIndex = this.availableTabs.indexOf(paymentTypeToToggle);
    }

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
@import "../../assets/_mixins.scss";

.payments-container > .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row td {
  border: 1px solid #ededed !important;
}

.payments-container > .v-data-table tbody {
  background-color: #fafafa;
}

.payments-container > .v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0 10px;
}

.payments-container > .v-data-table td,
.single-transfer > .v-data-table td {
  font-family: Muli;
  font-size: 12px !important;
  border: 0 !important;
}

.payments-container > .v-data-table__mobile-row {
  border-bottom: 1px solid #ededed;
}

.filter {
  min-width: 160px;
  max-width: 200px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  @include adjust-dimensions(100%, 100%);
  @include flexAttr(flex, center, center);
  z-index: 3999;
  background: rgba(0, 0, 0, 0.4);
}

.hide-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.copyable {
  @extend .hide-overflow;
  text-decoration: underline;
  cursor: pointer;

  a {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.crypto-address {
  @extend .hide-overflow;
  max-width: 15px;
}

.payment-link {
  @extend .copyable;
  max-width: 70%;
}

.transactionsCount {
  margin-right: 5px;
  border-radius: 100%;
  font-size: 9px;
  min-width: 21px;
  min-height: 21px;
  background: #2596ec;
  font-weight: 600;
  color: #fff;
  @include flexAttr(flex, center, center);
}

.completed,
.deposited,
.processed,
.received,
.sent .credit {
  color: #35cb80;
}

.pending,
.awaiting {
  color: #cebf3b;
}

.canceled,
.expired,
.failed,
.rejected,
.debit {
  color: #f14c4c;
}

.confirmed,
.new {
  color: #0080dd;
}
</style>
